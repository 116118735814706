import { ReactWrapper } from '$pages/common/react-wrapper';
import { bindable } from 'aurelia-framework';
import ServiceNowUserTicketDetails, { IServiceNowUserTicketDetailsProps } from './servicenow-userticket-details';

export class ServicenowUserTicketDetailsWrapper extends ReactWrapper<
  IServiceNowUserTicketDetailsProps
> {
  @bindable()
  ticket: string;

  constructor() {        
    super(ServiceNowUserTicketDetails, () => ({
      ticket: this.ticket
    }));
  }
}