import { BaseViewModel } from '../../../../common';
import { autoinject, bindable } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { IAsyncEntity, IAsyncEntityManager } from '../../../../../types';
import {
  IUserAccessStats,
  IUserEntityAccess,
  IUserAccessDescriptionRequest,
  IUserAlarmConnection
} from '../../../usermanagerReducer';
import { UserService } from '../../../../../services';
import { isNone } from '../../../../../utility';
import { rootState } from '../../../../../reducers';
import './user-access-description.css';
import { observable } from 'aurelia-binding';
import debounce from 'lodash.debounce';

interface IUserAccessDescriptionState {
  stats: IAsyncEntity<IUserAccessStats>;
  list: IAsyncEntity<IUserEntityAccess[]>;
  newEntities: IAsyncEntity<number>;
  deletedEntities: IAsyncEntity<number>;
  alarms: IAsyncEntity<IUserAlarmConnection[]>;
  newAlarms: IAsyncEntity<number>;
  removedAlarms: IAsyncEntity<number>;
}

@autoinject()
export class UserAccessDescription extends BaseViewModel<
  IUserAccessDescriptionState
> {
  @observable({ changeHandler: 'reattachmapstate' }) tab = 'sites';

  constructor(private userService: UserService) {
    super(getLogger('user-access-description'));
    this.reattachmapstate = debounce(this.reattachmapstate, 100);
  }

  @bindable({ changeHandler: 'reattachmapstate' }) userId: number | undefined;

  bind() {
    this.reattachmapstate();
  }

  reattachmapstate() {
    if (isNone(this.userId)) return;
    this.attachMapState(this.mapState(this.userId));
  }

  getEntitites = (request: IUserAccessDescriptionRequest) => {
    switch (this.tab) {
      case 'customers':
        return this.userService.getUserCustomerAccess(request);
      case 'sites':
        return this.userService.getUserSiteAccess(request);
      default:
        return IAsyncEntityManager.Create<IUserEntityAccess[]>(undefined);
    }
  };

  mapState = (userId: number) => ({
    usermanager
  }: rootState): IUserAccessDescriptionState => {
    const { pendingUserAccessDetails, pendingUsermanager } = usermanager;
    const {
      isSuperUser,
      hasAccessToAllBuids,
      hasAccessToAllCustomers,
      buidTags,
      customerTags,
      haulierTags
    } = pendingUserAccessDetails;
    const { email } = pendingUsermanager;
    const request = {
      userId,
      isSuperUser,
      hasAccessToAllBuids,
      hasAccessToAllCustomers,
      buidAccessFilters: buidTags,
      customerAccessFilters: customerTags,
      haulierAccessFilters: haulierTags,
      email
    };
    const userAccessDescriptionFetcher = this.userService.getUserAccessDescription(
      request
    );

    const entities = this.getEntitites(request);
    const alarms =
      this.tab === 'alarms'
        ? this.userService.getUserAlarmConnection(request)
        : IAsyncEntityManager.Create<IUserAlarmConnection[]>(undefined);
    const newAlarms = alarms
      .map(al => al.filter(a => a.added).length)
      .getAsyncEntity();
    const removedAlarms = alarms
      .map(al => al.filter(a => a.removed).length)
      .getAsyncEntity();

    return {
      stats: userAccessDescriptionFetcher.getAsyncEntity(),
      list: entities.getAsyncEntity(),
      deletedEntities: entities
        .map(s => s.filter(j => j.removed).length)
        .getAsyncEntity(),
      newEntities: entities
        .map(s => s.filter(j => j.added).length)
        .getAsyncEntity(),
      alarms: alarms.getAsyncEntity(),
      newAlarms,
      removedAlarms
    };
  };
}
