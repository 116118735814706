
import { CardWithHeader } from "$components/cards/mui-card";
import LoadingBarsReact from "$components/loading-bars/loading-bars.react";
import SimpleTableCell from "$components/tables/simple-table/simple-table-cell/simple-table-cell.react";
import SimpleTableRow from "$components/tables/simple-table/simple-table-row/simple-table-row.react";
import SimpleTable from "$components/tables/simple-table/simple-table/simple-table.react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { useQuery } from "$lib/hooks/fetch-utillities";
import { getReportScheduleDescription } from "$pages/scheduled-reports/report-schedule-list/report-schedule-list-helper";
import { mapReportEnumToTranslatedTitle } from "$pages/scheduled-reports/schedule-reports-helpers";
import { GetScheduledReportsForUserDocument } from "$typings/graphql-codegen";
import CardContent from "@mui/material/CardContent";
import React, { FC } from "react";
import './user-scheduled-reports.css';

export interface IUserScheduledReportsProps {
    userId : number
}

const UserScheduledReportsReact: FC<IUserScheduledReportsProps> = ({
  userId
}) => {
  const [t] = useCaseInsensitiveTranslation();
  
  const { data, loading } = useQuery(GetScheduledReportsForUserDocument, { userId });


  const getTranslatedReportType = mapReportEnumToTranslatedTitle(t);

  return (
    <div className="user-scheduled-reports-component">
      <CardWithHeader className='user-scheduled-reports-card' headerText={t('usermanager_userscheduledreports_title')}>
        {loading && (
          <CardContent className="component-card-content">                
              <LoadingBarsReact/>
          </CardContent>
        )}            

        {(!loading && data?.getUserScheduledReports.length == 0 ) && (
          <CardContent className="component-card-content overflow-y-auto">                
              <p>{t('usermanager_userscheduledreports_no_reports')}</p>
          </CardContent>
        )}            
       {(!loading && data?.getUserScheduledReports && data?.getUserScheduledReports.length > 0) && (
          <CardContent className="component-card-content overflow-y-auto">
            <SimpleTable layout='fixed'>
              <SimpleTableRow>
              <SimpleTableCell>Name</SimpleTableCell>
              <SimpleTableCell>Schedule</SimpleTableCell>
              <SimpleTableCell>Reports</SimpleTableCell>
              </SimpleTableRow>
                              {data &&
                                data.getUserScheduledReports.map((schedule, i) => (
                                  <SimpleTableRow  key={i}>
                                     <SimpleTableCell>{schedule.scheduleName}</SimpleTableCell>
                                     <SimpleTableCell>{getReportScheduleDescription(schedule.recurrence, schedule.recurrenceDetails, schedule.triggerTimeLocal, t)}</SimpleTableCell>
                                     <SimpleTableCell>{schedule.scheduledReportTypes.map(r => getTranslatedReportType(r)).join(', ')}</SimpleTableCell>
                                  </SimpleTableRow> 
                                ))}

                                </SimpleTable>                    
          </CardContent>
        )}            
      </CardWithHeader>      
    </div>
  );
};

export default UserScheduledReportsReact; 