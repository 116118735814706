import { default as React, FC, memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs  
} from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import {
  AllFeaturesAndColumnsDocument,
  AllRoleTemplatesQuery,
  SaveRoleTemplateDocument,
  SiteDetailsTabEnum
} from '$typings/graphql-codegen';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import ErrorText from '$components/texts/error-text/error-text.react';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import './role.edit.css';
import MuiTextField from '$components/textbox/mui-textfield.react';

type IDataItem = AllRoleTemplatesQuery['roleTemplates'][0];

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IRoleEditProps {
  data?: IDataItem[];
  roleTemplateId?: number;
  onCancel?: () => void;
  onSaved?: () => void;
}

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

const CustomTabPanelComponent: FC<TabPanelProps> = ({children, index, value, ...other} : TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{display: value == index ? 'flex' : 'none', flex: '1 0 0px', flexDirection: 'column', height:'100%', width:'100%'}}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

export const CustomTabPanel = memo(CustomTabPanelComponent);

const RoleEditComponent: FC<IRoleEditProps> = ({
  roleTemplateId,
  data,
  onCancel,
  onSaved,
  } : IRoleEditProps) => {
  const [currentTemplate, setCurrentTemplate] = useState<IDataItem | undefined>();  
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  
  const allFeaturesAndColumns = useQuery(AllFeaturesAndColumnsDocument);
  const [columnGroups, setColumnGroups] = useState<string[]>();
  const [featureGroups, setFeatureGroups] = useState<string[]>();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  
  useEffect(() => {
    const currentTemplate = data?.find(t => t.roleTemplateId == roleTemplateId);

    if (currentTemplate) {
      setCurrentTemplate(currentTemplate);            
    }

    //Create a sorted list of distinct column groups and feature groups
    if (allFeaturesAndColumns?.data) {
      setColumnGroups(allFeaturesAndColumns?.data.featuresAndColumns.columns
        .map(item => item.group)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort());

      setFeatureGroups(allFeaturesAndColumns?.data.featuresAndColumns.features
        .map(item => item.group)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort());
      
      //setProperties();
    }
  }, [data, allFeaturesAndColumns.data]);

  const saveTemplate = async () => {
    if (!currentTemplate)
      return;

    setSaving(true);
    
    await mutate(
      SaveRoleTemplateDocument,
      { roleTemplate: currentTemplate },
      false,   // no point, due nav away from page and to delete cached data onSuccess
      () => {
        onSaved && onSaved();
      },
      (e) => {
        // setErrorKey('UI_Common_Error_Save_Item_Failed');        
        setSaving(false);
        setError(e);
      }
    );

  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const toggleColumn = (columnId: number) => {
    if (!currentTemplate)
      return;

    const column = allFeaturesAndColumns.data?.featuresAndColumns.columns.find(c => c.columnId == columnId);

    if (!column)
      return;

    let newColumns = currentTemplate.columns;      

    if (currentTemplate.columns.find(c => c.columnId === columnId) !== undefined) {
      //Remove column
      newColumns = newColumns.filter(c => c.columnId !== columnId);
    }    
    else {
      //Add column
      newColumns.push(column);            
    }

    setCurrentTemplate({
      ...currentTemplate,
      columns: newColumns
    });
  }
  

  const setCurrentTemplateName = (name: string) => {
    if (currentTemplate) {
      setCurrentTemplate({
        ...currentTemplate,
        name
      });
    }    
  }

  const setIsInternal = (internalOnly: boolean) => {
    if (currentTemplate) {
      setCurrentTemplate({
        ...currentTemplate,
        internalOnly
      });      
    }    
  }

  const setSecurityLevel = (securityLevel: number) => {
    if (currentTemplate) {
      setCurrentTemplate({
        ...currentTemplate,
        securityLevel
      });
    }
  }

  const setInactivityExpirationDays = (inactivityExpirationDays: number) => {
    if (currentTemplate) {
      setCurrentTemplate({
        ...currentTemplate,
        inactivityExpirationDays
      });
    }
  }

  const setSiteDetailsDefaultTab = (tab: SiteDetailsTabEnum) => {
    if (currentTemplate) {
      setCurrentTemplate({
        ...currentTemplate,
        siteDetailsDefaultTab: tab
      });
    }
  }

  const toggleFeature = (featureId: number, access: 'read' | 'write' | 'delete') => {
    if (!currentTemplate)
      return;

    const feature = allFeaturesAndColumns.data?.featuresAndColumns.features.find(f => f.featureId == featureId);

    if (!feature)
      return;

    let newFeatures = currentTemplate.features; 
    const currentFeature = currentTemplate.features.find(f => f.featureId == featureId);
    
    if (currentFeature !== undefined) {
      if (access == 'read')
        currentFeature.read = !currentFeature.read;
      else if (access == 'write')
        currentFeature.write = !currentFeature.write;
      else if (access == 'delete')
        currentFeature.delete = !currentFeature.delete;

      //Remove complete feature as no access is given
      if (!(currentFeature.read || currentFeature.write || currentFeature.delete)) {
        newFeatures = newFeatures.filter(f => f.featureId !== featureId);
      }
    } else {
      newFeatures.push({
        ...feature,
        read: access == 'read',
        write: access == 'write',
        delete: access == 'delete'
      });
    }     

    setCurrentTemplate({
      ...currentTemplate,
      features: newFeatures
    });    
  }  

  const validColumnCount = (roleColumns?: number[], allColumns?: number[]) => {
    if (!roleColumns || !allColumns)
      return 0;

    let count = 0;
    roleColumns.forEach(c => {
      if (allColumns.indexOf(c) >= 0)
        count++;
    });

    return count;
  }

  return (
    <>
      <Card style={{height: '100%', margin: "1rem"}}>
        <CardContent style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <h2>Edit role template {currentTemplate?.name}</h2>
          <FormGroup>
            <FormControl>
              <FormLabel id="role-name">Name</FormLabel>
              <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', paddingTop: '1rem'}}>                          
                <MuiTextField value={currentTemplate?.name || ""}  type="text" sx={{minWidth: "400px"}} required onChange={(e) => setCurrentTemplateName(e.target.value)}  />
                <Button type="button"                   
                  variant="contained" 
                  disabled={saving}
                  sx={{margin: "auto 0"}}
                  startIcon={
                    saving ? (
                      <Icon name={'fa-spinner fa-pulse'} className="text" />
                    ) : <Icon name={'fa-save'} className="text" />
                  }
                  onClick={saveTemplate}>Save</Button>
                <Button type="button" sx={{margin: "auto 0"}} variant="contained" color='error' onClick={() => onCancel && onCancel()}>Cancel</Button>
                {error && (<ErrorText>{error}</ErrorText>)}
              </div>
            </FormControl>
            
            <FormControl>
              <FormLabel sx={{marginTop: "1rem"}} id="role-type">Type</FormLabel>
              <RadioGroup row value={currentTemplate?.internalOnly ? "1" : "0"} onChange={(e) => setIsInternal(e.target.value === "1")}>
                <FormControlLabel value="1" control={<Radio value="1" />} label="Internal" />
                <FormControlLabel value="0" control={<Radio value="0" />} label="External" />
              </RadioGroup>
            </FormControl>

            <FormControl>
              <FormLabel sx={{marginTop: "1rem"}} id="role-type">Security level</FormLabel>
              <RadioGroup row value={currentTemplate?.securityLevel ?? 0} onChange={(e) => setSecurityLevel(ensureNumber(e.target.value))}>
                <FormControlLabel value="0" control={<Radio value="0" />} label="None" />
                <FormControlLabel value="1" control={<Radio value="1" />} label="Low" />
                <FormControlLabel value="2" control={<Radio value="2" />} label="Medium" />
                <FormControlLabel value="3" control={<Radio value="3" />} label="High" />
              </RadioGroup>
            </FormControl>
            
            <FormControl>
              <FormLabel sx={{marginTop: "1rem"}} id="role-type">Account expiration days</FormLabel>
                <div style={{width: "100px"}}>
                  <MuiTextField value={currentTemplate?.inactivityExpirationDays ?? 180} 
                                onChange={(e) => e.target.value ? setInactivityExpirationDays(ensureNumber(e.target.value)) : setInactivityExpirationDays(0) } 
                                onBlur={(e) => ensureNumber(e.target.value) >= 14 ? setInactivityExpirationDays(ensureNumber(e.target.value)) : setInactivityExpirationDays(14) } 
                  />                          
                </div>
            </FormControl>            
          </FormGroup>
      
          <FormGroup>
            <Tabs value={selectedTabIndex} onChange={handleChange}>
              <Tab label='Columns' {...a11yProps(0)} />
              <Tab label='Features' {...a11yProps(1)} />
              <Tab label='Properties' {...a11yProps(2)} />
            </Tabs>
          </FormGroup>

          <FormGroup style={{display: 'flex', flex: '1 0 0px', flexDirection: 'column'}}>
            <CustomTabPanel value={selectedTabIndex} index={0}>                
              <div style={{display: 'flex', paddingTop: '1rem'}}>
                Select columns to be part of template ({validColumnCount(currentTemplate?.columns?.map(c => c.columnId), allFeaturesAndColumns.data?.featuresAndColumns.columns.map(c => c.columnId))} of {allFeaturesAndColumns.data?.featuresAndColumns.columns.length})
              </div>
              <div style={{overflow: 'auto', display: 'flex', flexDirection: 'row', width: '100%', flex: '1 0 0px', gap: '1rem', flexWrap: "wrap"}}>
                {columnGroups?.map((group, cgi) => (
                  <FormGroup style={{flexWrap: 'nowrap', minWidth: '200px', maxWidth: '200px'}} key={'columnGroup_' + cgi}>
                    <h3>{group}</h3>
                    {allFeaturesAndColumns.data?.featuresAndColumns.columns
                        .filter(c => c.group == group)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c, ci) => (
                          <FormControlLabel key={'columns_' + ci} control={<Checkbox 
                            onChange={(e) => toggleColumn(ensureNumber(e.target.value)) } value={c.columnId} 
                            checked={currentTemplate?.columns.find(c1 => c1.columnId == c.columnId) !== undefined || c.isDefault} 
                            disabled={c.isDefault} />} label={c.name} />
                        ))}
                  </FormGroup>
                ))}
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={selectedTabIndex} index={1}>
              <div style={{display: 'flex', paddingTop: '1rem'}}>
                Select features to be part of template ({currentTemplate?.features.length} of {allFeaturesAndColumns.data?.featuresAndColumns.features.length})
              </div>
              <div style={{overflow: 'auto', display: 'flex', flexDirection: 'row', width: '100%', flex: '1 0 0px', gap: '2rem'}}>
                {featureGroups?.map((group, fgi) => (
                  <FormGroup style={{flexWrap: 'nowrap', minWidth: '300px' }} key={'FeatureGroup_' + fgi}>
                    <h3 style={{textAlign: 'left'}}>{group}</h3>
                    <span style={{textAlign: 'right'}}>Read Write Delete</span>
                    {allFeaturesAndColumns.data?.featuresAndColumns.features
                        .filter(f => f.group == group)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((f, fi) => (
                            <div key={'feature_' + fi} className='flex jsb aicenter'>
                              <span>{f.name}</span>
                              <div>                              
                                <Checkbox onChange={() => toggleFeature(f.featureId, 'read')} disabled={!f.read} value={f.featureId} title='Read'
                                  checked={f.read && currentTemplate?.features.find(f1 => f1.featureId == f.featureId)?.read } />
                                <Checkbox onChange={() => toggleFeature(f.featureId, 'write')} disabled={!f.write} value={f.featureId} title='Write'
                                  checked={f.write && currentTemplate?.features.find(f1 => f1.featureId == f.featureId)?.write } />                                
                                <Checkbox onChange={() => toggleFeature(f.featureId, 'delete')} disabled={!f.delete} value={f.featureId} title='Delete'
                                  checked={f.delete && currentTemplate?.features.find(f1 => f1.featureId == f.featureId)?.delete } />
                              </div>                                                                                  
                            </div>
                        ))}                        
                  </FormGroup>
                ))}
              </div>  
            </CustomTabPanel>

            <CustomTabPanel value={selectedTabIndex} index={2}>
              <div style={{display: 'flex', paddingTop: '1rem', paddingBottom: '1rem'}}>
                Set other properties that all users created with this template we get set.
              </div>
              <div style={{overflow: 'auto', display: 'flex', flexDirection: 'row', width: '100%', flex: '1 0 0px', gap: '2rem'}}>
                <FormGroup style={{ flexWrap: 'nowrap', minWidth: '300px' }} key={'RoleProperties'}>
                  <div className="flex jsb aicenter">
                    <span>Site details default tab</span>
                    <Dropdown<SiteDetailsTabEnum>
                      replaceItemElement={item => (
                        <div>
                          <Icon name="fa-tab" />
                          <span>{item}</span>
                        </div>
                      )}
                      selected={currentTemplate?.siteDetailsDefaultTab ?? SiteDetailsTabEnum.Details}
                      items={[SiteDetailsTabEnum.Details, SiteDetailsTabEnum.Graph, SiteDetailsTabEnum.Tanks]}
                      itemClicked={item => {
                        setSiteDetailsDefaultTab(item?.value?.toString() as SiteDetailsTabEnum);
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
            </CustomTabPanel>
          </FormGroup>
        </CardContent>
      </Card>
    </>
  );
}

export const RoleEdit = memo(RoleEditComponent);
