import { isNumber, ensureNumber, getEntityOrUndefined, userHasFeature } from '../../../../utility';
import { IAsyncEntity, createFetchedEntity } from '../../../../types';
import { IUserManagerListUser } from '../usermanagerlist/usermanagerlist';
import { autoinject } from 'aurelia-framework';
import {
  UserService,
  BuidService,
  CustomerService,
  LanguageService,
  AccountService
} from '../../../../services';
import { getLogger } from 'aurelia-logging';
import { SecurityLevels, routeNames, features } from '../../../../config';
import { rootState } from '../../../../reducers';
import './usermanagerdetails.css';
import { Router } from 'aurelia-router';
import { AccessLevel, ILanguage } from '../../../../interfaces';
import { IUserContactsForUser } from '../../../common/user-contacts/user-contacts';
import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel';
import { NotificationService } from '../../../../services/notificationService';
import { EmailState } from '../../../../interfaces/enums/emailState';
import { userManagerUserModified } from '../../usermanagerReducer';
import { getSession } from '../../../../config/sessionService';
import { I18N } from 'aurelia-i18n';
import React from 'react';
import UserinfoForm, { IFormValues } from '../userinfo-form/userinfo-form';
import { createRoot, Root } from 'react-dom/client';

type tabs =
  | 'info'
  | 'info-edit'
  | 'security'
  | 'security-edit'
  | 'access-details'
  | undefined;
type urlParams = { tab: tabs; userId: string };
type stateParams = { tab: tabs; userId: number };

interface IUserManagerDetails {
  user: IAsyncEntity<IUserManagerListUser>;
  showTabBar: boolean;
  userId: number;
  showInfo: boolean;
  showSecurity: boolean;
  showSecurityEdit: boolean;
  menuIsOpenForEntity: any;
  isMobile: boolean;
  showInfoEdit: boolean;
  showContactsCard: boolean;
  showAccessInfoText: boolean;
  showConnections: boolean;
  showScheduledReports: boolean;
  languages: IAsyncEntity<ILanguage[]>;
  templateForContacts: IUserContactsForUser;  
}

@autoinject()
export class UserManagerDetails extends GraphQLBaseViewModel<
  IUserManagerDetails,
  void,
  void
> {
  constructor(
    private userService: UserService,
    private buidService: BuidService,
    private customerService: CustomerService,
    private accountService: AccountService,
    private languageService: LanguageService,
    private notification: NotificationService,
    private i18n : I18N,
    private router: Router
  ) {
    super(getLogger('usermanager-details'));
  }
  userinfoFormReact: HTMLElement | null;
  parent: HTMLElement | null;
  reactRoot: Root | null;

  trUI_UserManager_Details_SendInvite = this.i18n.tr('UI_UserManager_Details_SendInvite');
  trUI_UserManager_Details_Clone = this.i18n.tr('UI_UserManager_Details_Clone');
  trUI_UserManager_Details_Delete = this.i18n.tr('UI_UserManager_Details_Delete');
  trUI_UserManager_Details_Edit= this.i18n.tr('UI_UserManager_Details_Edit');

  userWasDeleted = false;
  hasAccessToCustomerManager = false;

  async activate({ userId, tab }: urlParams) {
    this.attachMapState(this.mapState({ userId: ensureNumber(userId), tab }), _ => !this.userWasDeleted);
    this.hasAccessToCustomerManager = userHasFeature(getSession(), features.manageCustomers, AccessLevel.Read);
  }

  stateChanged = (newState: IUserManagerDetails) => {
    if(this.state.user) {
      if(newState.showInfoEdit) {
        this.renderReact()
      } else {
        this.detachReact()
      }
    }
  }

  renderReact() {    
    if (!this.userinfoFormReact) return;
    if (!this.parent) {
      this.parent = this.userinfoFormReact.parentElement;
    }
    if (!this.parent) return;
            
    //Prevent react sub component rendering more than once 
    //and overwriting the user's modified values #7547
    if (this.reactRoot)
       return;
    
    const user = getEntityOrUndefined(this.state.user);

    const defaultValues = {
      internalUser: user?.internalUser,
      loginName: user?.loginName,
      name: user?.name,
      email: user?.email,
      telephoneNumber: user?.telephoneNumber,
      description: user?.description,
      neverExpires: user?.neverExpires,
      inactivityExpirationDays: user?.inactivityExpirationDays,
      customerId: user?.customerId,
      buidId: user?.buidId,
      serviceNowTicket: user?.serviceNowTicket,
      siteDetailsDefaultTab: user?.siteDetailsDefaultTab,
    }

    const element = React.createElement(UserinfoForm, { onCancel: this.userInfoEditCancelled, onSave: this.saveUserInfo, defaultValues, showTemplateDropdown: false });
    this.reactRoot = createRoot(this.parent)
    this.reactRoot.render(element);
  }

  detachReact(){   
    if (this.parent) {
      this.reactRoot?.unmount()
      this.reactRoot = null;
    }
  }

  detached() {
    this.detachReact()
  }

  mapState = ({ userId, tab = 'info' }: stateParams) => ({
    device,
    application
  }: rootState): IUserManagerDetails => {
    const usermanagerFetcher = this.userService.getUserManagerUser(userId);
    const userFetcher = usermanagerFetcher.bind(user =>
      this.userService.getUser(user.userId, user.userToken)
    );
    const buidsFetcher = this.buidService.getAllBuids();
    const customerFetcher = usermanagerFetcher.bind(user =>
      user.customerId
        ? this.customerService.getById(user.customerId)
        : createFetchedEntity(undefined)
    );
    const asyncUser = usermanagerFetcher.map4(
      userFetcher,
      buidsFetcher,
      customerFetcher,
      (manager, user, buids, customer): IUserManagerListUser => ({
        ...user,
        buidId: manager.buidId,
        customerId: manager.customerId,
        buid: buids.find(buid => buid.buidId === manager.buidId),
        customer,
        neverExpires: manager.neverExpires,
        internalUser: manager.internalUser,
        description: manager.description,
        lastLogin: manager.lastLogin,
        serviceNowTicket: manager.serviceNowTicket,
        loginName: manager.loginName,        
        invitationSentDate: manager.invitationSentDate,
        invitationLanguage: manager.invitationLanguage,
        securityLevel: manager.securityLevel,
        securityLevelDisplay:
          manager.securityLevel !== undefined
            ? SecurityLevels[manager.securityLevel]
            : undefined,
        templateId: undefined,
        template: undefined,
        buidTags: manager.buidTags,
        customerTags: manager.customerTags,
        haulierTags: manager.haulierTags,
        deleted: false,
        lastAppliedRoleTemplate: manager.lastAppliedRoleTemplate,
        siteDetailsDefaultTab: manager.siteDetailsDefaultTab,
        inactivityExpirationDays: manager.inactivityExpirationDays,
      })
    );

    const isMobile = device.screenSize === 'mobile';
    const isSuperUser = getSession().currentUser.isSuperUser ?? false;


    return {
      userId,
      menuIsOpenForEntity: application.menuIsOpenForEntity,
      showInfo: (!isMobile || tab === 'info') && tab !== 'info-edit',
      showSecurity: !isMobile || tab === 'security',
      showSecurityEdit: tab === 'security-edit',
      showTabBar: isMobile && tab !== 'info-edit' && tab !== 'security-edit',
      showInfoEdit: tab === 'info-edit',
      user: asyncUser.getAsyncEntity(),      
      showConnections: !isMobile || tab === 'access-details',
      showAccessInfoText: isMobile && tab === 'info',
      showContactsCard: !isMobile || tab === 'info',
      showScheduledReports: !isMobile || isSuperUser,
      isMobile,
      languages: this.languageService.getLanguages().getAsyncEntity(),
      templateForContacts: asyncUser
        .map(user => ({
          userId: user.userId,
          email: user.email || '',
          buidId: user.buidId,
          customerId: user.customerId,
          name: user.name || '',
          telephoneNumber: user.telephoneNumber || '',
          description: user.description
        }))
        .getEntityOrUndefined()
    };
  };

  confirm: 'invitate' | 'deletion' | 'clone' | undefined;


  saveUserInfo = async (userInfo: IFormValues) => {    
    const user = getEntityOrUndefined(this.state.user);
    await this.userService.updateUserInformation({...userInfo, userId: this.state.userId, lastAppliedRoleTemplate: user?.lastAppliedRoleTemplate, invitationSentDate: user?.invitationSentDate, invitationLanguage: user?.invitationLanguage, lastLogin: user?.lastLogin} as IUserManagerListUser)
    this.userInfoEditSaved()
    this.revalidateAllActiveQueries();
  }

  userInfoEditSaved = async () => {
    if (this.state.isMobile) this.router.navigateBack();
    else
      this.router.navigateToRoute(
        routeNames.usermanagerdetails,
        { tab: 'info', userId: this.state.userId },
        { replace: true }
      );
  };

  userInfoEditCancelled = this.userInfoEditSaved;

  userAccessEditClicked = () => {
    this.moveToTab('security-edit');
  };

  userAccessEditingDone = this.userInfoEditSaved;

  editUserInformation = () => {
    this.moveToTab('info-edit');
  };

  moveToTab = (tab: tabs) => {
    const { userId } = this.state;
    const shouldReplace =
      !this.state.isMobile || (tab !== 'info-edit' && tab !== 'security-edit');
    this.router.navigateToRoute(
      routeNames.usermanagerdetails,
      { tab, userId },
      { replace: shouldReplace }
    );
  };

  deleteUser = async () => {
    await this.userService.removeUsersAsync([this.state.userId],
      () => {
        this.userWasDeleted = true;
        this.router.navigateToRoute(routeNames.usermanagerlist, {}, { replace: true });
      });
  };

  sendingInvitationEmail: boolean;

  sendInvitation = async (userId: number, languageId: number) => {
    this.sendingInvitationEmail = true;
    
    try {
      await this.accountService.sendInviteAsync(userId, languageId);
      this.confirm = undefined;
                  
      const languageFetcher = this.languageService.getLanguages();
      const languages = languageFetcher.getEntityOrUndefined();

      if (languages)
      {
        const language = languages.find(l => l.languageId == languageId);

        if (language) {
            const user = getEntityOrUndefined(this.state.user);
                       
            if (user) {
              // user.invitationLanguage = language?.description;
              // user.invitationSentDate = new Date().toLocaleString();               

              this.dispatch(userManagerUserModified({
                ...user,
                invitationLanguage: language?.description,
                invitationSentDate: new Date()              
              }));
              //this.dispatch(userManagerInvitationChanged({userId, language: user.invitationLanguage}));
            }
        }        
      }

      this.notification.notify({
        type: 'CUSTOM',
        level: 'info',
        text: 'User invitation sent via email.',
        timestamp: new Date().toString(),
        acknowledged: false
      });      
    } catch (error) {
      let errorMessage = this.constructErrorMessage(error);
      this.notification.notify({
        type: 'CUSTOM',
        level: 'error',
        text: 'Error sending user invitation. ' + errorMessage,
        timestamp: new Date().toString(),
        acknowledged: false
      });
    }
    this.sendingInvitationEmail = false;
    
    
  };

  toUserList = () => {
    this.router.navigateToRoute(routeNames.usermanagerlist);
  };

  constructErrorMessage(error: any): string {
    if (isNumber(error) && error in EmailState) {
      if (EmailState[error] === 'Invalid') {
        return 'Invalid email address';
      }
    }
    return error;
  }

  cancelClickedOnClone(): void {
    this.confirm = undefined;
  }

  /** 
   * Show the newly created user after cloning: 
   */
  userWasCloned(newUserId: number): void {
    this.confirm = undefined;
    this.router.navigateToRoute(routeNames.usermanagerdetails, { userId: newUserId.toString() })
  }
}
