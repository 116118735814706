import React, {FC, HTMLAttributes} from 'react';
import './card-header-with-menu.css'
import classNames from "classnames";

interface ICardHeaderWithMenuProps extends HTMLAttributes<HTMLElement> {
  actions?: JSX.Element[];
  menuStyle?: 'space-between' | 'reverse' | 'needed' | 'fill';
}

const CardHeaderWithMenu: FC<ICardHeaderWithMenuProps> = (props) => {
  return (
    <div className={classNames('card-header-with-menu-component', props.className)}>
      <h2 className={props.menuStyle}>
        { props.children }
      </h2>
      <div className={classNames('menu', props.menuStyle)}>
        { props.actions }
      </div>
    </div>
  )
}

export default CardHeaderWithMenu;
