import { RouterConfiguration } from 'aurelia-router';
import { routeNames } from '../../config';
import { PLATFORM } from 'aurelia-pal';
import './usermanager.css';

export class UserManager {
  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: '',
        name: routeNames.usermanagerlist,
        moduleId: PLATFORM.moduleName(
          './modules/usermanagerlist/usermanagerlist'
        ),
        title: 'UI_Titles_Usermanager'
      },
      {
        route: 'details/:userId/:tab?',
        name: routeNames.usermanagerdetails,
        moduleId: PLATFORM.moduleName(
          './modules/usermanagerdetails/usermanagerdetails'
        )
      },
      {
        route: 'create',
        name: routeNames.usermanagercreation,
        moduleId: PLATFORM.moduleName(
          './modules/usermanagercreation/usermanagercreation'
        )
      },
      {
        route: 'roles/:roleTemplateId?',
        name: routeNames.usermanagerroles,
        moduleId: PLATFORM.moduleName(
          './modules/roles/roleswrapper'
        )
      }
    ]);
  }
}
