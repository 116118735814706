import { groupBy } from '$lib/arrayHelpers';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { orderByPredicate } from '$lib/sorting';
import { RoleTemplate, ServerSiteColumn, UserAccess } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import { TemplateCompareAction } from '../../user-security-editor.react';
import UserSecurityColumnsEdit from './modules/user-security-columns-edit';
import UserSecurityColumnsView from './modules/user-security-columns-view';

interface IUserSecurityColumnsEditorProps {
  editMode: boolean;
  isMobile: boolean;
  userAccess: UserAccess;
  roleTemplates: RoleTemplate[];
}

export interface ExtendedServerSiteColumn extends ServerSiteColumn{
  action?: TemplateCompareAction
}

export interface GroupedServerSiteColumn {
    group: string;
    columns: ExtendedServerSiteColumn[]
}

export function useGroupedAndOrderUserColumns(userColumns: ExtendedServerSiteColumn[]): GroupedServerSiteColumn[] {
  const [t] = useCaseInsensitiveTranslation();
  const grouped = groupBy(userColumns, c => c.columnGroupKey);
  const orderedColumns = Object.entries(grouped).map(([group, columns]) => ({
    group,
    columns: orderByPredicate(
      columns,
      c => t(c.languageKey).trim(),
      'asc',
      true
    )
  }));

  const groupedUserColumns = orderByPredicate(
    orderedColumns,
    c => t(c.group).trim(),
    'asc',
    true
  );
  return groupedUserColumns
}

const UserSecurityColumnsEditor: FC<IUserSecurityColumnsEditorProps> = ({
  editMode,
  isMobile,
  userAccess,
  roleTemplates
}) => {
  const groupedUserColumns = useGroupedAndOrderUserColumns(userAccess.userColumns)
  return (
    <>
      {!editMode && (
        <UserSecurityColumnsView
          groupedUserColumns={groupedUserColumns}
          isMobile={isMobile}
        />
      )}
      {editMode && (
        <UserSecurityColumnsEdit
          userAccess={userAccess}
          roleTemplates={roleTemplates}
          isMobile={isMobile}
          userColumns={userAccess.userColumns}
        />
      )}
    </>
  );
};

export default UserSecurityColumnsEditor;
