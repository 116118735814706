import { bindable } from 'aurelia-framework';
import { ReactWrapper } from '$pages/common/react-wrapper';
import UserScheduledReportsReact, { IUserScheduledReportsProps } from './user-scheduled-reports.react';

export class UserScheduledReports extends ReactWrapper<IUserScheduledReportsProps> {
  @bindable() userId: number;
  
  constructor() {
    super(UserScheduledReportsReact, () => ({
      userId: this.userId      
    }));
  }  
}
