import { bindable, inlineView } from 'aurelia-framework';
import React from 'react';
import CreateUserCardReact from './create-user-card.react';
import { createRoot, Root } from 'react-dom/client';

@inlineView(`<template><div ref="react"></div></template>`)
export class CreateUserCard {
  react: HTMLElement | null;
  parent: HTMLElement | null;
  @bindable({ changeHandler: 'rerender' }) cancel: () => {};
  reactRoot: Root | null;
  @bindable({ changeHandler: 'rerender' }) closeModal: () => {};

  createReactElement() {
    return React.createElement(CreateUserCardReact, {
      cancel: this.cancel,
    });
  }

  attached() {
    if (!this.react) return;
    if (!this.parent) {
      this.parent = this.react.parentElement;
    }
    if (!this.parent) return;
    const element = this.createReactElement();
    this.reactRoot = createRoot(this.parent)
    this.reactRoot.render(element);
    }

  detached() {
    if (this.parent) {
      this.reactRoot?.unmount()
    }
  }
  rerender() {
    if (this.parent) {
      const element = this.createReactElement();
      this.reactRoot?.render(element);
    }
  }
}
