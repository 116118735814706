import { getLogger } from 'aurelia-logging';
import gql from 'graphql-tag';
import { bindable, computedFrom } from 'aurelia-framework';
import './user-contacts.css';
import { GraphQLBaseViewModel } from '../GraphQLBaseViewModel';
import {
  UserManagerContactsQuery,
  ContactInput,
  UserManagerContactsQueryVariables,
  UserManagerContactsQuery_contacts_edges,
  UserManagerUpdatePendingContactVariables,
  UserManagerRemoveContactMutationVariables
} from '../../../../custom_typings/graphql';
import { isNone } from '../../../utility';
import { getSession } from '../../../config/sessionService';

export type IUserContactsForUser =
  | undefined
  | {
      userId: number;
      email: string;
      buidId: number | undefined;
      customerId: number | undefined;
      name: string;
      telephoneNumber: string;
      description: string;
    };

export class UserContacts extends GraphQLBaseViewModel<
  void,
  UserManagerContactsQuery,
  UserManagerContactsQueryVariables
> {
  @bindable({ changeHandler: 'bind' }) user: IUserContactsForUser;

  constructor() {
    super(getLogger(UserContacts.name));
  }

  query = gql`
    query UserManagerContactsQuery($userId: ID) {
      contacts(userId: $userId) {
        edges {
          email
          avatarUrl
          telephoneNumber
          name
          contactId
          customerId
          buidId
          description
          userId
          syncedWithUserInformation
          isContactFor {
            contactTypes
            siteId
            siteName
          }
        }
      }
    }
  `;

  bind() {
    if (isNone(this.user)) return;
    this.variables = {
      userId: this.user.userId.toString()
    };
  }

  getPendingContactForNewContact(): ContactInput | undefined {
    if (!this.user) return;
    return {
      ...this.user,
      userId: this.user.userId.toString(),
      syncedWithUserInformation: true
    };
  }

  pendingRemoveContact: UserManagerContactsQuery_contacts_edges | undefined;
  pendingContact: UserManagerContactsQuery_contacts_edges | undefined;

  pendingContactChanged(
    property: keyof UserManagerContactsQuery_contacts_edges,
    value: UserManagerContactsQuery_contacts_edges[keyof UserManagerContactsQuery_contacts_edges]
  ) {
    if (!this.pendingContact) return;
    this.pendingContact = {
      ...this.pendingContact,
      [property]: value
    };
  }

  async savePendingContact({
    email,
    buidId,
    contactId,
    customerId,
    name,
    telephoneNumber,
    userId,
    description,
    syncedWithUserInformation
  }: UserManagerContactsQuery_contacts_edges) {
    const variables: UserManagerUpdatePendingContactVariables = {
      entity: {
        email,
        buidId,
        contactId,
        customerId,
        name,
        telephoneNumber,
        userId: userId ? userId.toString() : null,
        description,
        syncedWithUserInformation
      }
    };
    await this.runQuery(
      gql`
        mutation UserManagerUpdatePendingContact($entity: ContactInput!) {
          upsertContact(entity: $entity) {
            contactId
          }
        }
      `,
      variables
    );

    this.revalidateAllActiveQueries();
    this.pendingContact = undefined;
  }

  async removeContact(
    confirmed: boolean,
    contact: UserManagerContactsQuery_contacts_edges
  ) {
    if (!confirmed && contact.isContactFor.length) {
      this.pendingRemoveContact = contact;
      return;
    }
    const variables: UserManagerRemoveContactMutationVariables = {
      contactId: contact.contactId
    };
    await this.runQuery(
      gql`
        mutation UserManagerRemoveContactMutation($contactId: ID!) {
          deleteContact(contactId: $contactId)
        }
      `,
      variables
    );

    await this.revalidateAllActiveQueries();
    this.pendingRemoveContact = undefined;
  }

  @computedFrom('user')
  get isCurrentUser() {
    const session = getSession();
    return this.user && this.user.userId === session.currentUser.userId;
  }
}
