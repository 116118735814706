import { BaseViewModel } from '../../../../common';
import { autoinject } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { bindable } from 'aurelia-templating';
import { UserService } from '../../../../../services';
import { IUserAccessStats } from '../../../usermanagerReducer';
import { IAsyncEntity } from '../../../../../types';
import { isNone } from '../../../../../utility';
import './user-access-short-description.css';

@autoinject()
export class UserAccessShortDescription extends BaseViewModel<
  IAsyncEntity<IUserAccessStats>
> {
  constructor(private userService: UserService) {
    super(getLogger('user-access-short-description'));
  }

  @bindable({ changeHandler: 'reattachmapstate' }) userId: number | undefined;
  @bindable clicked: Function;

  bind() {
    this.reattachmapstate();
  }

  reattachmapstate() {
    if (isNone(this.userId)) return;
    this.attachMapState(this.mapState(this.userId));
  }

  mapState = (userId: number) => () =>
    this.userService.getUserAccessDescription({ userId }).getAsyncEntity();
}
