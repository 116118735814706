import CardContent from '$components/cards/card-content/card-content.react';
import Icon from '$components/icons/icon/icon.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { FC } from 'react';
import { GroupedServerSiteColumn } from '../user-security-column-editor';
import classNames from "classnames";

interface IUserSecurityColumnsViewProps {
  isMobile: boolean
  groupedUserColumns: GroupedServerSiteColumn[]
}

//Returns groups with at least one selected column
function groupsWithSelectedColumns(groupedUserColumns : GroupedServerSiteColumn[]) {
  return groupedUserColumns.filter(g => g.columns.filter(c => c.selected).length > 0);
}

const UserSecurityColumnsView: FC<IUserSecurityColumnsViewProps> = ({
  groupedUserColumns,
  isMobile,
}) => {
  const [t] = useCaseInsensitiveTranslation();

  return (
    <CardContent className={classNames(
      isMobile && 'fill-height',
      'user-security-editor-card-content',
      'columns-table'
    )}>
      {groupsWithSelectedColumns(groupedUserColumns).map((columngroup, i) => (
        <SimpleTable key={`gv_${columngroup.group}${i}`}>
          <SimpleTableRow className="header strong">
            <SimpleTableCell className="text-description">
              {t(columngroup.group)}
            </SimpleTableCell>
            <SimpleTableCell></SimpleTableCell>
          </SimpleTableRow>
          {columngroup.columns.filter(userColumn => userColumn.selected).map(userColumn => (
            <SimpleTableRow key={`uv_${userColumn.columnId}${i}`}>
              <SimpleTableCell className="text-description">
                {t(userColumn.languageKey)}
              </SimpleTableCell>

              <SimpleTableCell>
                {userColumn.selected && <Icon name="fa-check" />}
              </SimpleTableCell>
            </SimpleTableRow>
          ))}
        </SimpleTable>
      ))}
    </CardContent>
  );
};

export default UserSecurityColumnsView;
