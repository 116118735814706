import { default as React, FC, useState, memo} from 'react';
import { Button, Card, CardContent, FormControl, FormGroup, FormLabel, TextField } from "@mui/material";
import Dropdown from "$components/dropdowns/dropdown/dropdown.react";
import { AllRoleTemplatesQuery, DeleteRoleTemplateDocument, DuplicateRoleTemplateDocument, NewRoleTemplateDocument } from '$typings/graphql-codegen';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Icon from '$components/icons/icon/icon.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import ErrorText from '$components/texts/error-text/error-text.react';

type IDataItem = AllRoleTemplatesQuery['roleTemplates'][0];

interface IRoleListProps
{
  data?: IDataItem[];
  onEdit?: (selectedRoleTemplate: number) => void;
  onDelete?: (selectedRoleTemplate: number) => void;
  onDuplicate?: (selectedRoleTemplate: number) => void;
  onNew?: () => void;
}

const RoleListComponent: FC<IRoleListProps> = ({onEdit, data}) => {        
  const [ selectedTemplateId, setSelectedTemplateId ] = useState<number | undefined>(undefined);
  const [ showNewNameModal, setShowNewNameModal] = useState<Boolean>(false);
  const [ newName, setNewName] = useState<string>();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const editClicked = (roleTemplateId? : number) => {
    setSelectedTemplateId(roleTemplateId);

    if (onEdit && roleTemplateId)
      onEdit(roleTemplateId);
  }

  const duplicateRoleTemplate = (roleTemplateId? : number) => {
    if (!roleTemplateId)
      return;

      setShowNewNameModal(true);
  }

  const newRoleTemplate = () => {
    setSelectedTemplateId(undefined);
    setShowNewNameModal(true);
  }

  const doDelete = async () => {
    if (!selectedTemplateId)
      return;

    await mutate(
        DeleteRoleTemplateDocument,
        { roleTemplateId: selectedTemplateId },
        true,
        () => {
          setShowDeleteConfirmation(false);
        },
        (e) => {                   
          setError(e);
        }        
      );

    setSelectedTemplateId(undefined);
  }

  const duplicateOrCreateNewRoleTemplate = async () => {
    setSaving(true);

    if (selectedTemplateId) {
      await mutate(
        DuplicateRoleTemplateDocument,
        { roleTemplateId: selectedTemplateId, newName },
        true,
        (res) => {
          setSaving(false);
          setShowNewNameModal(false);

          if (res.duplicateRoleTemplate?.success)
            setSelectedTemplateId(ensureNumber(res.duplicateRoleTemplate?.message));        
        },
        (e) => {         
          setError(e);
        }
      );
    }
    else {
      await mutate(
        NewRoleTemplateDocument,
        { newName },
        true,
        (res) => {
          setSaving(false);
          setShowNewNameModal(false);

          if (res.newRoleTemplate?.success)
            setSelectedTemplateId(ensureNumber(res.newRoleTemplate?.message));
        },
        (e) => {         
          setError(e);
        }
      );
    }
    
    setSaving(false);
  }

  return (
    <>
        {showDeleteConfirmation && (
          <RequireConfirmation 
            confirmType="delete"
            entityName=''
            submitText="Confirm delete"            
            onConfirm={doDelete}
            onCancel={() => setShowDeleteConfirmation(false)}>
            <p>
              Are you sure you want to delete this role template?
            </p>

            {error && (<ErrorText>{error}</ErrorText>)}
          </RequireConfirmation> 
        )}

        {showNewNameModal && (
        <ModalCard>
          <CardHeader>
            <p>New name</p>
          </CardHeader>

          <CardContent className="bodycontent">                        
            <FormGroup>
              <FormControl>
                <FormLabel id="role-name">Enter new name for template</FormLabel>              
                <TextField onChange={(e) => setNewName(e.target.value)} />
              </FormControl>
            </FormGroup>

            {error && (<ErrorText>{error}</ErrorText>)}
          </CardContent>
          
          <CardFooter>
            <div className="action-buttons margin-top">
              <Button variant="outlined" onClick={() => setShowNewNameModal(false)}>Cancel</Button>&nbsp;                
              <Button variant="contained" 
                onClick={() => duplicateOrCreateNewRoleTemplate() }
                disabled={saving} 
                  startIcon={
                    saving ? (
                      <Icon name={'fa-spinner fa-pulse'} className="text" />
                    ) : <Icon name={'fa-save'} className="text" />
                  }
                >Save</Button>
            </div>
         </CardFooter> 
        </ModalCard>
        )}
        
        <Card sx={{margin: "1rem"}}>
          <CardContent sx={{width: 370}}>
            <a href="/usermanager">&lt;Back to usermanager</a>
            <h2>Role templates</h2>          
            Select existing role template, or create a new:
            <Dropdown<IDataItem>
                  items={data}
                  itemClicked={item => typeof item?.value === 'number' && setSelectedTemplateId(item?.value)}
                  selected={selectedTemplateId?.toString()}
                  displayPath="name"
                  valuePath="roleTemplateId"
            />  
    
            <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', paddingTop: '1rem'}}>                          
              <Button type="button" disabled={!selectedTemplateId} variant="contained" onClick={() => editClicked(selectedTemplateId)}>Edit</Button>
              <Button type="button" disabled={!selectedTemplateId} variant="outlined" onClick={() => duplicateRoleTemplate(selectedTemplateId)}>Duplicate</Button> 
              <Button type="button" variant="outlined" onClick={newRoleTemplate}>New</Button>
              <Button type="button" disabled={!selectedTemplateId} variant="contained" color='error' onClick={() => setShowDeleteConfirmation(true)}>Delete</Button> 
            </div>
          </CardContent>
        </Card>      
    </>
  );              
};

export const RoleList = memo(RoleListComponent);