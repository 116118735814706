import CardHeaderWithMenu from '$components/cards/card-header-with-menu/card-header-with-menu.react';
import CardTabBarItem from '$components/cards/card-tab-bar/card-tab-bar-item/card-tab-bar-item.react';
import CardTabBar from '$components/cards/card-tab-bar/card-tab-bar.react';
import Card from '$components/cards/card/card.react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { useIsMobile } from '$lib/hooks/isMobile';
import React, { FC, useState, useMemo, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getUserFeatures } from '../../../../../../src/config/sessionService';
import UserSecurityAccessEditor from './modules/user-scurity-access-editor/user-security-access-editor';
import {
  RoleTemplatesDocument,
  SaveUserAccessDocument,
  SaveUserAccessMutationVariables,
  UserAccess,
  UserAccessDocument,
  UserAccessFeature,
  UserAccessFeatureGroup
} from '$typings/graphql-codegen';

import './user-security-editor.css';
import UserSecurityFeatureEditor from './modules/user-security-feature-editor/user-security-feature-editor';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Button from '$components/buttons/button.react';
import UserSecurityColumnsEditor, { ExtendedServerSiteColumn } from './modules/user-security-column-editor/user-security-column-editor';
import { mapToSecurityLevel } from './modules/user-scurity-access-editor/modules/user-security-access-view';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import CardContent from '$components/cards/card-content/card-content.react';
import { SecurityLevel } from '../../../../../../src/interfaces/enums/securityLevel';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IUserAccessDetails } from '$pages/usermanager/usermanagerReducer';
import classNames from "classnames";

export interface IUserSecurityEditorProps {
  userId: number;
  isEditing: boolean;
  editClicked: () => {};
  editDone: () => {};
  formChanged: (values: Partial<IUserAccessDetails>) => void;
}

export type TemplateCompareAction = 'added' | 'removed' | 'unchanged';

export interface ExtendedUserAccessFeature extends UserAccessFeature {
  deleteAction?: TemplateCompareAction;
  readAction?: TemplateCompareAction;
  writeAction?: TemplateCompareAction;
}

export interface ExtendedUserAccessFeatureGroup extends UserAccessFeatureGroup {
  features: ExtendedUserAccessFeature[];
}

export interface FormValues {
  isSuperUser: boolean | undefined;
  securityLevel: string;
  hasAccessToAllCustomers: boolean;
  hasAccessToAllBuids: boolean;
  customerTags: string[];
  buidTags: string[];
  haulierTags: string[];
  userColumns: ExtendedServerSiteColumn[];
  userFeatures: ExtendedUserAccessFeatureGroup[];
  appliedRoleTemplateInfoName?: string;
}

const mapToSecurityLevelToInt = (value: string) => {
  switch (value.toLowerCase()) {
    case 'none':
      return SecurityLevel.None;
    case 'low':
      return SecurityLevel.Low;
    case 'medium':
      return SecurityLevel.Medium;
    case 'high':
      return SecurityLevel.High;
    default:
      throw 'Invalid securitylevel';
  }
};

const createFormDefaultValues = (userAccess: UserAccess): FormValues => ({
  isSuperUser: !!userAccess.details.isSuperUser,
  securityLevel: mapToSecurityLevel(userAccess.details.securityLevel),
  hasAccessToAllCustomers: userAccess.details.hasAccessToAllCustomers,
  hasAccessToAllBuids: userAccess.details.hasAccessToAllBuids,
  customerTags: userAccess.details.customerTags,
  buidTags: userAccess.details.buidTags,
  haulierTags: userAccess.details.haulierTags,
  userColumns: userAccess.userColumns,
  userFeatures: userAccess.userFeatures,
  appliedRoleTemplateInfoName: undefined
});

const UserSecurityEditor: FC<IUserSecurityEditorProps> = ({
  userId,
  isEditing,
  editDone,
  editClicked,
  formChanged
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [tab, setTab] = useState<'access' | 'features' | 'columns'>('access');
  const isMobile = useIsMobile();
  const { data, loading } = useQuery(UserAccessDocument, { userId });
  const { data: rolesTemplatesdata, loading: templatesLoading } = useQuery(
    RoleTemplatesDocument
  );

  const methods = useForm<FormValues>();

  const formValues = methods.watch();
  useEffect(() => {
    if (formValues) {
      formChanged({
        ...formValues,
        securityLevel: formValues.securityLevel
          ? mapToSecurityLevelToInt(formValues.securityLevel)
          : undefined
      });
    }
  }, [formValues]);

  const features = getUserFeatures();

  const onSubmit: SubmitHandler<FormValues> = async formValues => {
    if (!data) return;

    const variables: SaveUserAccessMutationVariables = {
      userAccess: {
        userId: data.userAccess.userId,
        lastAppliedRoleTemplate: formValues.appliedRoleTemplateInfoName
          ? { name: formValues.appliedRoleTemplateInfoName, time: new Date() }
          : undefined,
        details: {
          buidTags: formValues.buidTags,
          customerTags: formValues.customerTags,
          hasAccessToAllBuids: formValues.hasAccessToAllBuids,
          hasAccessToAllCustomers: formValues.hasAccessToAllCustomers,
          haulierTags: formValues.haulierTags,
          securityLevel: mapToSecurityLevelToInt(formValues.securityLevel),
          internalUser: data?.userAccess.details.internalUser,
          isSuperUser: formValues.isSuperUser
        },
        userColumns: formValues.userColumns.map(uc => {
          delete uc.action
          return uc
        }),
        userFeatures: formValues.userFeatures.map(uf => ({
          ...uf,
          features: uf.features.map(f => {
            const feature = f;
            delete feature.readAction;
            delete feature.deleteAction;
            delete feature.writeAction;
            return { ...feature };
          })
        }))
      }
    };
    await mutate(SaveUserAccessDocument, variables, true, editDone);
  };

  const filteredRoleTemplates = !!data?.userAccess.details.internalUser
    ? rolesTemplatesdata?.roleTemplates
    : rolesTemplatesdata?.roleTemplates.filter(
        template => !template.internalOnly
      );

  useMemo(() => {
    if (data) {
      const defaultValues = createFormDefaultValues(data.userAccess);
      methods.reset(defaultValues);
    }
  }, [data]);

  return (
    <div className="user-security-editor-component">
      <Card
        className={classNames('user-security-editor-card', isEditing && 'isEditing')}
      >
        {!(isEditing && isMobile) && (
          <CardHeaderWithMenu
            actions={
              features.manageUsers.write && !isEditing
                ? [
                    <ClickableIcon
                      key="fa-pencil"
                      name="fa-pencil"
                      onClick={editClicked}
                      title={'UI_UserManager_Details_EditAccess'}
                    />
                  ]
                : undefined
            }
          >
            Authorization
          </CardHeaderWithMenu>
        )}
       
        <CardTabBar>
          <CardTabBarItem
            onClick={() => setTab('access')}
            active={tab === 'access'}
          >
            Access
          </CardTabBarItem>
          <CardTabBarItem
            onClick={() => setTab('features')}
            active={tab === 'features'}
          >
            Features
          </CardTabBarItem>
          <CardTabBarItem
            onClick={() => setTab('columns')}
            active={tab === 'columns'}
          >
            Columns
          </CardTabBarItem>
        </CardTabBar>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {tab === 'access' && data?.userAccess && !loading && (
              <UserSecurityAccessEditor
                isMobile={isMobile}
                editMode={isEditing}
                userAccess={data.userAccess}
              />
            )}
            {tab === 'features' &&
              data?.userAccess &&
              !!filteredRoleTemplates && (
                <UserSecurityFeatureEditor
                  isMobile={isMobile}
                  editMode={isEditing}
                  userAccess={data.userAccess}
                  roleTemplates={filteredRoleTemplates}
                />
              )}
            {tab === 'columns' &&
              data?.userAccess &&
              !!filteredRoleTemplates && (
                <UserSecurityColumnsEditor
                  isMobile={isMobile}
                  editMode={isEditing}
                  userAccess={data.userAccess}
                  roleTemplates={filteredRoleTemplates}
                />
              )}
            
            {(loading || (tab === 'features' || tab === "columns") && templatesLoading)  && (
              <CardContent>
                <Skeleton rowCount={10} />
              </CardContent>
            )}
            {isEditing && (
              <CardFooter className="flex jsb action-bar">
                <Button variant="secondary" onClick={() => {
                  editDone()
                  if(data) {
                    methods.reset(createFormDefaultValues(data.userAccess))
                  }
                }}>
                  {t('ui_common_cancel')}
                </Button>
                <Button
                  processing={methods.formState.isSubmitting}
                  type="submit"
                >
                  {t('ui_common_savechanges')}
                </Button>
              </CardFooter>
            )}
          </form>
        </FormProvider>
      </Card>
    </div>
  );
};

export default UserSecurityEditor;
