import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { orderByPredicate } from '$lib/sorting';
import { RoleTemplate, UserAccess } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import UserSecurityFeatureEdit from './modules/user-security-feature-edit';
import UserSecurityFeatureView from './modules/user-security-feature-view';

interface IUserSecurityFeatureEditorProps {
  editMode: boolean;
  isMobile: boolean;
  userAccess: UserAccess;
  roleTemplates: RoleTemplate[];
}

const UserSecurityFeatureEditor: FC<IUserSecurityFeatureEditorProps> = ({
  editMode,
  isMobile,
  userAccess,
  roleTemplates
}) => {
  const [t] = useCaseInsensitiveTranslation();

  const orderedUserFeatureGroup = orderByPredicate(
    userAccess.userFeatures,
    u => t(u.groupKey).trim(),
    'asc'
  ).map(userFeatureGroup => ({
    ...userFeatureGroup,
    features: orderByPredicate(
      userFeatureGroup.features,
      u => t(u.translationKey).trim(),
      'asc'
    )
  }));


  return (
    <>
      {!editMode && (
        <UserSecurityFeatureView orderedUserFeatureGroup={orderedUserFeatureGroup} isMobile={isMobile} />
      )}
      {editMode && (
        <UserSecurityFeatureEdit
          userAccess={userAccess}
          orderedUserFeatureGroup={orderedUserFeatureGroup}
          roleTemplates={roleTemplates}
        />
      )}
    </>
  );
};

export default UserSecurityFeatureEditor;
