import CardContent from '$components/cards/card-content/card-content.react';
import { UserAccess } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import UserSecurityAccessEdit from './modules/user-security-access-edit';
import UserSecurityAccessView from './modules/user-security-access-view';
import classNames from "classnames";

interface IUserSecurityAccessEditorProps {
  editMode: boolean;
  isMobile: boolean
  userAccess: UserAccess;
}

const UserSecurityAccessEditor: FC<IUserSecurityAccessEditorProps> = ({
  editMode,
  isMobile,
  userAccess
}) => (
  <CardContent className={classNames(
    editMode && isMobile && 'fill-height',
    'user-security-editor-card-content',
    'access-tab'
  )}>
    {!editMode && <UserSecurityAccessView userAccess={userAccess} />}
    {editMode && <UserSecurityAccessEdit userAccess={userAccess} />}
  </CardContent>
);

export default UserSecurityAccessEditor;
