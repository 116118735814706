import ActionButton from '$components/actions/action-button/action-button.react';
import ActionContentItem from '$components/actions/action-content-item/action-content-item.react';
import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import Checkbox from '$components/checkbox/checkbox.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  RoleTemplate,
  ServerSiteColumn,
  UserAccess
} from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../../../user-security-editor.react';
import {
  ExtendedServerSiteColumn,
  useGroupedAndOrderUserColumns
} from '../user-security-column-editor';
import './user-security-columns-edit.css';
import classNames from "classnames";

interface IUserSecurityColumnsEditProps {
  roleTemplates?: RoleTemplate[];
  userAccess: UserAccess;
  isMobile: boolean;
  userColumns: ServerSiteColumn[];
}

const UserSecurityColumnsEdit: FC<IUserSecurityColumnsEditProps> = ({
  roleTemplates,
  isMobile,
  userColumns,
  userAccess
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [extendedColumns, setExtendedColumns] = useState<
    ExtendedServerSiteColumn[]
  >(userColumns);
  const groupedUserColumns = useGroupedAndOrderUserColumns(extendedColumns);
  const { control, setValue, watch } = useFormContext<FormValues>();

  const formUserColumns = watch('userColumns');

  const toggleAllUserColumns = () => {
    const columnsToChange = userAccess.userColumns
      .filter(c => !c.disabled && !c.connectedToUserFeature)
      .map<ServerSiteColumn>(c => ({
        ...c,
        selected: !!formUserColumns.some(c => !c.selected && !c.disabled),
        sortIndex: 0,
        columnWidth: 0
      }));
    setValue('userColumns', columnsToChange);
    setValue('appliedRoleTemplateInfoName', undefined);
  };

  const setColumnsFromTemplate = ({ columns, name }: RoleTemplate) => {
    const columnsToChange = userAccess.userColumns.map<ServerSiteColumn>(c => ({
      ...c,
      selected: columns.find(c2 => c2.columnId == c.columnId) !== undefined && !c.disabled,
      sortIndex: 0,
      columnWidth: 0
    }));
    const checkedBeforeTemplate = userAccess.userColumns.filter(
      uc => uc.selected
    );
    const checkedAfterTemplate = columnsToChange.filter(c => c.selected);
    const removedByTemplate = checkedBeforeTemplate.filter(
      b =>
        !checkedAfterTemplate.map(a => a.columnId).includes(b.columnId) &&
        !b.disabled
    );
    const addedByTemplate = checkedAfterTemplate.filter(
      a =>
        !checkedBeforeTemplate.map(b => b.columnId).includes(a.columnId) &&
        !a.disabled
    );

    const extendColumns = userAccess.userColumns.map(c => ({
      ...c,
      action: removedByTemplate.map(r => r.columnId).includes(c.columnId)
        ? 'removed'
        : addedByTemplate.map(a => a.columnId).includes(c.columnId)
        ? 'added'
        : 'unchanged'
    }));

    setExtendedColumns(extendColumns as ExtendedServerSiteColumn[]);
    setValue('userColumns', columnsToChange);
    setValue('appliedRoleTemplateInfoName', name);
  };

  return (
    <div className="user-security-columns-edit">
      <CardContent>
        <ButtonGroup className="flex">
          <ActionButton
            buttonType="secondary"
            text="Set from template"
            className="toggleButton"
          >
            {roleTemplates?.map(template => (
              <ActionContentItem
                key={template.name}
                onClick={() => setColumnsFromTemplate(template)}
              >
                {template.name}
              </ActionContentItem>
            ))}
          </ActionButton>
          <Button
            variant="secondary"
            onClick={toggleAllUserColumns}
            className="toggleButton"
          >
            Toggle all columns
          </Button>
        </ButtonGroup>
      </CardContent>
      <CardContent
        className={classNames(
          isMobile && 'fill-height',
          'user-security-editor-card-content',
          'columns-table'
        )}>
        <Controller
          name="userColumns"
          control={control}
          render={({ field }) => (
            <>
              {groupedUserColumns.map((columngroup, i) => (
                <SimpleTable key={`ge_${columngroup.group}${i}`}>
                  <SimpleTableRow className="header strong">
                    <SimpleTableCell className="text-description">
                      {t(columngroup.group)}
                    </SimpleTableCell>
                    <SimpleTableCell></SimpleTableCell>
                  </SimpleTableRow>
                  {columngroup.columns.map((userColumn, i) => (
                    <SimpleTableRow key={`ue_${userColumn.columnId}${i}`}>
                      <SimpleTableCell className="text-description">
                        {t(userColumn.languageKey)}
                      </SimpleTableCell>
                      <SimpleTableCell>
                        <Checkbox
                          checked={
                            formUserColumns.find(
                              v => v.columnId === userColumn.columnId
                            )?.selected
                          }
                          className={classNames(userColumn.action)}
                          disabled={userColumn.disabled}
                          onChange={() => {
                            const existingValues = field.value;
                            const filteredExistingValues = existingValues.filter(
                              ev => ev.columnId !== userColumn.columnId
                            );
                            field.onChange([
                              ...filteredExistingValues,
                              {
                                ...userColumn,
                                selected: !userColumn.selected
                              }
                            ]);
                            setValue('appliedRoleTemplateInfoName', undefined);
                          }}
                        />
                      </SimpleTableCell>
                    </SimpleTableRow>
                  ))}
                </SimpleTable>
              ))}
            </>
          )}
        ></Controller>
      </CardContent>
    </div>
  );
};

export default UserSecurityColumnsEdit;
