import { useQuery } from '$lib/hooks/fetch-utillities';
import { RoleTemplate, RoleTemplatesDocument } from '$typings/graphql-codegen';
import React, { FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';

interface IRoleTemplateDropdownProps {
  selected?: RoleTemplate | string ;
  itemClicked?: (params: { roleTemplateId?: string; name?: string }) => void;
  placeholder?: string;
  internalUser?: boolean
  topElement?: string;
  error?: boolean;
  replaceTopElement?: (query: string) => ReactElement;
}

const RoleTemplateDropdown: FC<IRoleTemplateDropdownProps> = ({
  selected,
  itemClicked,
  placeholder,
  topElement,
  internalUser,
  error,
  replaceTopElement
}) => {
  const { data, loading } = useQuery(RoleTemplatesDocument);
  
  const filteredRoleTemplates = internalUser
    ? data?.roleTemplates
    : data?.roleTemplates.filter(
        template => !template.internalOnly
      );
  return (
    <Dropdown<RoleTemplate>
      items={filteredRoleTemplates}
      displayPath="name"
      selected={selected}
      loading={loading}
      valuePath="roleTemplateId"
      itemClicked={params =>
        itemClicked &&
        itemClicked({
          roleTemplateId: params?.value?.toString(),
          name: params?.text
        })
      }
      placeholder={placeholder}
      topElement={topElement}
      error={error}
      replaceTopElement={replaceTopElement}
    />
  );
};

export default RoleTemplateDropdown;
