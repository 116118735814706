import { default as React, FC, useState, memo} from 'react';
import { AllRoleTemplatesDocument } from '$typings/graphql-codegen';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { RoleList } from './modules/role.list';
import { RoleEdit } from './modules/role.edit';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

const RoleEditorComponent: FC = () => {        
  const { data } = useQuery(AllRoleTemplatesDocument);
  const [ selectedTemplate, setSelectedTemplate ] = useState<number | undefined>(undefined);

  return (
    <>
      {!selectedTemplate &&
        <RoleList 
          data={data?.roleTemplates} 
          onEdit={(roleTemplateId) => {
            setSelectedTemplate(roleTemplateId); 
          }}                     
          />
      }

      {selectedTemplate &&
        <RoleEdit           
          data={data?.roleTemplates} 
          roleTemplateId={selectedTemplate} 
          onCancel={ () => setSelectedTemplate(undefined)}          
          onSaved={ async () => {
            setSelectedTemplate(undefined); 
            await revalidateAllActiveQueries();
          }}
        />
      }
    </>
  );              
};

export const RoleEditor = memo(RoleEditorComponent);