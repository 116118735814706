import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { routeNames } from '../../../../config';
import { getSession } from '../../../../config/sessionService';
import './usermanagerlist.css';
import { Router } from 'aurelia-router';
import { GraphQLInfiniteListViewModel, WithoutPagination } from '../../../common/GraphQLInfiniteListViewModel';
import { 
  ColumnKeyAndTitle,
  SortDirection, UserManagerListExcelQuery, UserManagerListExcelQueryVariables, UserManagerListQuery, UserManagerListQueryVariables, 
  UserManagerListQuery_elasticSearchPages_userManager_data_edges, 
  UserManagerSortEnum, UserManagerListIdsQuery, UserManagerListIdsQueryVariables, ElasticSearchPage
} from '../../../../../custom_typings/graphql'
import { gql } from 'graphql-request';
import { IGridColumn, IGridSelectableRowsConfig, Grid, IFilterDefinition } from '../../../../components/grid/grid';
import { I18N } from 'aurelia-i18n';
import { BlobService, UserService } from '../../../../services';
import { IBuid, ICustomer, IFilterGroup, IUser } from '../../../../interfaces';
import { SecurityLevel } from '../../../../interfaces/enums/securityLevel';
import { IRoleTemplate } from '../../../../interfaces/entity/iRoleTemplate';
import { getDate30DaysAgo, isEmpty, isSomething } from '../../../../utility';
import { LocalSettings } from '../../../../services/localSettingsService';
import { IAppliedRoleTemplateInfo } from '$pages/usermanager/usermanagerReducer';
import { SiteDetailsTabEnum } from '$typings/graphql-codegen';

export interface IUserManagerListUser extends IUser {
  customerId: number | undefined;
  buidId: number | undefined;
  buid: IBuid | undefined;
  customer: ICustomer | undefined;
  loginName: string;
  neverExpires: boolean | undefined;
  internalUser: boolean | undefined;
  serviceNowTicket: string | undefined;
  invitationSentDate: string | Date | undefined;
  invitationLanguage: string | undefined;
  description: string;  
  lastLogin: string | undefined;
  securityLevel: SecurityLevel | undefined;
  securityLevelDisplay: string | undefined;
  templateId: number | undefined;
  template: IRoleTemplate | undefined;
  customerTags: string[];
  buidTags: string[];
  haulierTags: string[];
  deleted: boolean | undefined;
  lastAppliedRoleTemplate?: IAppliedRoleTemplateInfo;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
  inactivityExpirationDays: number | undefined;
}

export interface IUserManagerListParams {
  totalUsers: boolean | undefined;
  internalUsers: boolean | undefined;
  externalUsers: boolean | undefined;
  activeUsersLast30Days: boolean | undefined;
  deletedUsersLast30Days: boolean | undefined;
  manageUserAccess: boolean | undefined;
  expiresSoon: boolean | undefined
}

const defaultSelectedColumns: UserManagerSortEnum[] = [
  UserManagerSortEnum.UserId,
  UserManagerSortEnum.Username,
  UserManagerSortEnum.Name,
  UserManagerSortEnum.Email,
  UserManagerSortEnum.Description,
  UserManagerSortEnum.BUID,
  UserManagerSortEnum.LastLogin,
  UserManagerSortEnum.SecurityLevel,
  UserManagerSortEnum.InactivityExpirationDays,
]

const userManagerSelectedColumnsKey = 'userManagerSelectedColumns';

function hasAnyValidParams(params: IUserManagerListParams): boolean {
  return !!(params.totalUsers
    || params.internalUsers
    || params.externalUsers
    || params.activeUsersLast30Days
    || params.deletedUsersLast30Days
    || params.manageUserAccess
    || params.expiresSoon
  );
}


@autoinject()
export class UserManagerList extends GraphQLInfiniteListViewModel<UserManagerListQuery, UserManagerListQueryVariables, UserManagerListQuery_elasticSearchPages_userManager_data_edges> {
  constructor(
    private router: Router,
    private i18n: I18N,
    private blobService: BlobService,
    private userService: UserService
  ) {
    super(
      UserManagerList.name, 
      d => d.elasticSearchPages.userManager.data.totalCount, 
      d => d.elasticSearchPages.userManager.data.edges, 
      false, 
      true
    );
  }

  freshnessPageToCheck = ElasticSearchPage.UserManager;
  
  selectedColumns = LocalSettings.getSettingParsed(
    userManagerSelectedColumnsKey,
    defaultSelectedColumns
  );

  query = gql`
    query UserManagerListQuery($filters: String, $freeTextQuery: [String!], $sortDirection: SortDirection!, $sortProperty: UserManagerSortEnum!, $first: Int!, $offset: Int!, $deletedOnly: Boolean = false) {
      elasticSearchPages {
        userManager(filters: $filters, freeTextQuery: $freeTextQuery, sortDirection: $sortDirection, sortProperty: $sortProperty, deletedOnly: $deletedOnly) {
          data(first: $first, offset: $offset) {
            totalCount
            edges {
              userId
              loginName
              accessToUserManager
              name
              email
              serviceNowTicket
              description
              lastLogin
              reminderEmailSent
              phoneNumber
              isSuperUser
              internalUser
              invitationSentDate
              language
              roleTemplateDescription
              inactivityExpirationDays
              deleted
              modified
              securityLevel {
                securityLevelId
                securityLevelName
              }
              customer {
                customerId
                customerName
              }  
              buid {
                buidId
                buidName
              }
            }
          }
        }
      }
    }
  `

  defaultvariables: WithoutPagination<UserManagerListQueryVariables> = {
    filters: '[]',           // #6740 set a default value so variablesHasChanged() will work in all circumstances
    freeTextQuery: [],       // same as over
    sortDirection: SortDirection.Asc,
    sortProperty: UserManagerSortEnum.Username,
  }

  @computedFrom('variables.freeTextQuery')
  get freeTextQuery() {
        return this.variables?.freeTextQuery?.join('\n')
  }

  freeTextQueryChanged (newValue: string = '') {
    newValue = newValue?.trim();
    const oldValue = this.freeTextQuery?.trim() || ''
    if(oldValue === newValue?.trim()) return;
    this.setNewTextQueryInVariables(newValue);       
  }

 
  activate(params?: IUserManagerListParams) {
    if (!isSomething(params) || Object.values(params).length == 0 || !hasAnyValidParams(params)) {
      return;
    }
    
    this.applyParamFilters(params);
  }

  applyParamFilters(params: IUserManagerListParams) {
    this.clearFilters();
    const filters: IFilterGroup[] = [];

    if (params.deletedUsersLast30Days) {
      this.selectableRowsConfig = this.noSelectSelectableRowsConfig;
    }
    // if (params.totalUsers) {}
    if (params.internalUsers) {
      filters.push({
        exclude: false,
        field: "internaluser",
        filters: [
          {
            value: true
          }
        ],
        type: "boolean"
      });
    }
    if (params.externalUsers) {
      filters.push({
        exclude: true,
        field: "internaluser",
        filters: [
          {
            value: true
          }
        ],
        type: "boolean"
      });
    }
    if (params.activeUsersLast30Days) {
      const date30daysAgoString = getDate30DaysAgo().toISOString();
      filters.push({
        exclude: false,
        field: "lastlogin",
        type: "date",
        filters: [
          {
            operator: 1,
            value: date30daysAgoString,
            symbol: ">"
          }
        ],
        partialMatch: true
      });
    }
    if (params.manageUserAccess) {
      filters.push({
        exclude: false,
        field: "accessToUserManager",
        type: "boolean",
        filters: [
          {
            value: true
          }
        ]
      });
    }

    if (params.expiresSoon) {
      filters.push({
        exclude: false,
        field: "expiressoon",
        type: "boolean",
        filters: [
          {
            value: true
          }
        ],
      });
    }

    this.variables = {
      ...this.defaultvariables,
      filters: filters.length ? JSON.stringify(filters) : this.defaultvariables.filters, // #6846, must come after default variables
      deletedOnly: !!params.deletedUsersLast30Days
    }
  }

  clearFilters(navWithoutParams: boolean = false) {
    this.selectableRowsConfig = this.allowSelectSelectableRowsConfig;
    this.variables = this.defaultvariables;
    if (navWithoutParams) {
      this.router.navigateToRoute(routeNames.usermanagerlist);
    }
  }

  @computedFrom('variables.filters')
  get activeFilters(): IFilterGroup[] {
      const serializedFilters = this.variables?.filters
      return serializedFilters ? JSON.parse(serializedFilters) : []
  }

  filterChanged(newfilter: IFilterGroup) {
    const filters = this.activeFilters.filter(f => f.field !== newfilter.field);

    if(newfilter.filters?.length)
      filters.push(newfilter);

    this.activeFiltersChanged(filters)
  }

  activeFiltersChanged(filters: IFilterGroup[]) {
    if(!this.variables) return;
    this.variables = {
      ...this.variables,
      filters: JSON.stringify(filters)
    };
  }

  rowClass = (row: UserManagerListQuery_elasticSearchPages_userManager_data_edges) => {
    if (row && row.accessToUserManager)
      return 'danger'

    if (row && row.deleted)
      return 'deleted'

    return '';
  }

  clearFilter(filter: IFilterDefinition) {
    const newFilters = this.activeFilters.filter(f => f.field !== filter.property);
    if(this.activeFilters.length !== newFilters.length)
      this.activeFiltersChanged(newFilters)
  }

  isSuperUser() {
    const session = getSession();
    return session.currentUser.isSuperUser;
  }

  setNewTextQueryInVariables(newValue = '') {
    this.variables = {
        ...this.variables,
        freeTextQuery: newValue.split('\n').filter(e => !isEmpty(e)).map(f => f?.trim())
    }
  }

  loadNewData() {
    this.revalidateAllActiveQueries();
  }

  noSelectSelectableRowsConfig: IGridSelectableRowsConfig<UserManagerListQuery_elasticSearchPages_userManager_data_edges> = {
    rowKey: () => [],
    selectedRowsChanged: (selectedRows: number[]) => {
      this.selectedRows = selectedRows;
    },
    selectSingleRow: true,
    selectText: 'UI_Usermanagerlist_SelectionBar_Description',
    fetchAllIds: this.fetchAllIds.bind(this),
  }
  allowSelectSelectableRowsConfig: IGridSelectableRowsConfig<UserManagerListQuery_elasticSearchPages_userManager_data_edges> = {
    rowKey: row => {
      if (row.deleted) return []
      return [row.userId]
    },
    selectedRowsChanged: (selectedRows: number[]) => {
      this.selectedRows = selectedRows;
    },
    selectText: 'UI_Usermanagerlist_SelectionBar_Description',
    fetchAllIds: this.fetchAllIds.bind(this),
  }
  
  selectableRowsConfig = this.allowSelectSelectableRowsConfig;

  abortController: AbortController | undefined;
    async fetchAllIds(){
        if(this.abortController)
            this.abortController.abort();

        const query = gql`
        query UserManagerListIdsQuery(
            $freeTextQuery: [String!]
            $sortDirection: SortDirection
            $sortProperty: UserManagerSortEnum
            ) {
              elasticSearchPages {
                  userManager(freeTextQuery: $freeTextQuery, sortDirection: $sortDirection, sortProperty: $sortProperty) {
                      data {
                          edges {
                              userId
                          }
                      }
                  }
              }
            }
        `
        const variables = this.variables
        const { promise, abortController } = this.runQuery<UserManagerListIdsQuery, UserManagerListIdsQueryVariables>(query,variables);
        this.abortController = abortController;

        try {
            const ids = await promise;
            this.abortController = undefined;
            return ids.elasticSearchPages.userManager.data.edges.map(s => s.userId);     

        } catch(error) {
            if(abortController?.signal.aborted)
                return undefined;
            throw error;
        }
    }

  private gridviewmodel: Grid<UserManagerListQuery_elasticSearchPages_userManager_data_edges> | undefined;
  selectedRows: number[] = [];
  @bindable() deleteConfirmation = false;
  deleteSelectedUsers = async () => {
    await this.userService.removeUsersAsync(this.selectedRows);
    this.deleteConfirmation = false;
    this.selectedRows = [];
    if(this.gridviewmodel) {
      this.gridviewmodel.clearSelectedRows();
    }
  };

  goToUserDetails = ({ userId, deleted  }: IUserManagerListUser) => {
    if (deleted) return;
    this.router.navigateToRoute(routeNames.usermanagerdetails, { userId });
  };

  goToUserCreation = () => {
    this.router.navigateToRoute(routeNames.usermanagercreation);
  };

  goToRoleEditor = () => {
    this.router.navigateToRoute(routeNames.usermanagerroles);
  };

  downloadExcel = async () => {

    const query = gql`
      query UserManagerListExcelQuery($filters: String, $columnKeyAndTitle: [ColumnKeyAndTitle!]!, $freeTextQuery: [String!], $sortDirection: SortDirection!, $sortProperty: UserManagerSortEnum!) {
        elasticSearchPages {
          userManager (filters: $filters, freeTextQuery: $freeTextQuery, sortDirection: $sortDirection, sortProperty: $sortProperty) {
            excelSheet(columnKeyAndTitle: $columnKeyAndTitle)
          }
        }
      }
    `

    const variables: UserManagerListExcelQueryVariables = { 
      ...this.variables, 
      columnKeyAndTitle: this.columns.map<ColumnKeyAndTitle>(column => ({
        key: column.columnKey,
        title: this.i18n.tr(column.columnTitle)
      }))
    };

    try {
      const { promise } = this.runQuery<
        UserManagerListExcelQuery,
        UserManagerListExcelQueryVariables
      >(query, variables);
      const excelSheet = await promise;

      await this.blobService.downloadFileFromUrl(
        excelSheet.elasticSearchPages.userManager.excelSheet,
        'Usermanager-export.xlsx'
      );
    } catch (error) {
      throw new Error('Could not download excel');
    }

  };

  getLink(row: UserManagerListQuery_elasticSearchPages_userManager_data_edges) {
    if (row.deleted) return;
    return `/usermanager/details/${row.userId}`;
  }

  sortMeny = false;
  sortByColumn(columnKey: UserManagerSortEnum) {
    this.variables = this._getVariablesForNewSort(columnKey);
    this.sortMeny = false;
  }

  _getVariablesForNewSort(column: UserManagerSortEnum) {
    return {
        ...this.variables,
        sortProperty: column,
        sortDirection: column === this.variables.sortProperty ? 
            this.variables.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc : 
            SortDirection.Asc,
    } 
  }

  persistColumns(selectedColumns: UserManagerSortEnum[]) {
    this.selectedColumns = selectedColumns;
    LocalSettings.setSetting(userManagerSelectedColumnsKey, JSON.stringify(this.selectedColumns));
  }

  superUserLanguageKeys : Record<string, string> = {
    'true' : this.i18n.tr('UI_Common_Yes'),
    'false' : this.i18n.tr('UI_Common_No'),
  }

  columns: IGridColumn<UserManagerListQuery_elasticSearchPages_userManager_data_edges>[] = [
    {
      columnTitle: 'UI_UserManager_ID',
      property: e => e.userId,
      required: true,
      type: 'integer',
      width: 100,
      columnKey: UserManagerSortEnum.UserId,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_ID',
        property: 'userid',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_UserName',
      property: e => e.loginName,
      type: 'string',
      columnKey: UserManagerSortEnum.Username,
      sortedByInitially: 'asc',
      width: 200,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_UserName',
        property: 'username',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_Name',
      property: e => e.name,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.Name,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_Name',
        property: 'name',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_RoleTemplateDescription',
      property: e => e.roleTemplateDescription,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.RoleTemplateDescription,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_RoleTemplateDescription',
        property: 'roletemplatedescription',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_ServiceNowTicket',
      property: e => e.serviceNowTicket,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.ServiceNowTicket,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_ServiceNowTicket',
        property: 'serviceNowTicket',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_Email',
      property: e => e.email,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.Email,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_Email',
        property: 'email',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_Description',
      property: e => e.description,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.Description,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_Description',
        property: 'description',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_AccessTag',
      property: e => e.customer?.customerName,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.Customer,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_AccessTag',
        property: 'customer',
        type: 'ref'
      }
    },
    {
      columnTitle: 'UI_UserManager_Buid',
      property: e => e.buid?.buidName,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.BUID,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_Buid',
        property: 'buid',
        type: 'ref'
      }
    },
    {
      columnTitle: 'UI_UserManager_LastLogin',
      property: e => e.lastLogin,
      type: 'datetime',
      width: 200,
      columnKey: UserManagerSortEnum.LastLogin,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_LastLogin',
        property: 'lastlogin',
        type: 'date'
      }
    },
    {
      columnTitle: 'UI_UserManager_Security',
      property: e => e.securityLevel.securityLevelName,
      type: 'string',
      width: 100,
      columnKey: UserManagerSortEnum.SecurityLevel,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_Security',
        property: 'security',
        type: 'ref'
      }
    },
    {
      columnTitle: 'UI_UserManager_ReminderEmailSent',
      property: e => e.reminderEmailSent,
      type: 'datetime',
      width: 200,
      columnKey: UserManagerSortEnum.ReminderEmailSent,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_ReminderEmailSent',
        property: 'reminderemail',
        type: 'date'
      }
    },
    {
      columnTitle: 'UI_UserManager_InternalUser',
      property: e => e.internalUser ? 'fa-solid fa-check' : '',
      type: 'icon',      
      columnKey: UserManagerSortEnum.InternalUser,
      width: 100,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_InternalUser',
        property: 'internaluser',
        type: 'bool',
        settings: {
          values: [{
              value: true,
              header: 'Only',
              sub: "Only internal users"
          }, {
              value: false,
              header: 'Hide',
              sub: "Only external users"
          },
          {
            value: undefined,
            default: true,
            header: "Both",
            sub: "Do not filter by this property"
        }]
        }
      }
    },
    {
      columnTitle: 'UI_UserManager_PhoneNumber',
      property: e => e.phoneNumber,
      type: 'string',
      width: 200,
      columnKey: UserManagerSortEnum.PhoneNumber,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_PhoneNumber',
        property: 'phonenumber',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_IsSuperUser',
      property: e => e.isSuperUser ? 'fa-solid fa-check' : '',            
      type: 'icon',
      width: 100,
      columnKey: UserManagerSortEnum.IsSuperUser,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_IsSuperUser',
        property: 'issuperuser',
        type: 'bool',
        settings: {
          values: [{
              value: true,
              header: 'Only',
              sub: "Show superusers"
          }, {
              value: false,
              header: 'Hide',
              sub: "Hide superusers"
          }, {
              value: undefined,
              default: true,
              header: "Both",
              sub: "Do not filter by this property"
          }]
        }
      }      
    },
    {
      columnTitle: 'UI_UserManager_InvitationSentDate',
      property: e => e.invitationSentDate,
      type: 'datetime',
      columnKey: UserManagerSortEnum.InvitationSentDate,
      width: 200,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_InvitationSentDate',
        property: 'invitationdate',
        type: 'date'
      }
    },
    {
      columnTitle: 'UI_UserManager_Language',
      property: e => e.language,
      type: 'string',
      columnKey: UserManagerSortEnum.Language,
      width: 100,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_Language',
        property: 'language',
        type: 'string'
      }
    },
    {
      columnTitle: 'UI_UserManager_HasAccessToUserManager',
      property: e => e.accessToUserManager ? 'fa-solid fa-check' : '',
      type: 'icon',
      width: 100,
      columnKey: UserManagerSortEnum.AccessToUserManager,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_HasAccessToUserManager',
        property: 'accessToUserManager',
        type: 'bool',
        settings: {
          values: [{
              value: true,
              header: 'Only',
              sub: "Only users with useraccess"
          }, {
              value: false,
              header: 'Hide',
              sub: "Only users without useraccess"
          },
          {
            value: undefined,
            default: true,
            header: "Both",
            sub: "Do not filter by this property"
        }]
        }
      }
    },
    {
      columnTitle: 'UI_UserManager_Modified',
      property: e => e.modified,
      type: 'datetime',
      columnKey: UserManagerSortEnum.Modified,
      width: 200,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_Modified',
        property: 'modified',
        type: 'date'
      }
    },
    {
      columnTitle: 'UI_UserManager_ExpiresDays',
      property: e => e.inactivityExpirationDays,
      type: 'integer',
      columnKey: UserManagerSortEnum.InactivityExpirationDays,
      width: 200,
      sort: (_list, _order, column) => this.sortByColumn(column.columnKey),
      filter: {
        name: 'UI_UserManager_ExpiresDays',
        property: 'inactivityExpirationDays',
        type: 'number'
      }
    },
  ]
}
