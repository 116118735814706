import { autoinject, bindable, customElement } from "aurelia-framework";
import { getLogger } from "aurelia-logging";
import gql from "graphql-tag";
import { CreateUserByCloningPermissions, CreateUserByCloningPermissionsInput, CreateUserByCloningPermissionsVariables } from "../../../../../../custom_typings/graphql";
import { ensureNumber, isNone } from "../../../../../utility";
import { GraphQLBaseViewModel } from "../../../../common/GraphQLBaseViewModel";


@autoinject()
@customElement("user-clone-permissions-editor")
export class UserClonePermissionsEditor extends GraphQLBaseViewModel<
  void,
  void,
  void
> {
  constructor() 
  {
    super(getLogger('user-clone-permissions-editor'));
  }

  @bindable() cancelClicked: Function;

  @bindable() userWasCloned: Function;

  @bindable() userIdToClone: number;

  @bindable() originalDescription?: string;

  createUserByCloningError?: string = undefined;

  // Properties to fill in for the cloned user:
  loginName?: string;
  name: string;
  email?: string;
  phone: string;
  description: string;


  validateLoginName = (): boolean | string => {
    if (!isNone(this.loginName) && this.loginName.toString().length > 2)  {
      return true;
    }
    return 'UI_UserManager_InvalidUsername';
  }

  bind() 
  {
    if (!isNone(this.originalDescription)) {
      this.description = this.originalDescription;
    }
  }

  detached() {
    super.detached();
    this.loginName = undefined;
    this.name = '';
    this.email = undefined;
    this.phone = '';
    this.description = '';
    this.createUserByCloningError = undefined;
  }

  public clearStateAndCancel(): void {
    if (this.cancelClicked) {
      this.cancelClicked();
    }
  }

  /** 
   * Clone user and return the user id of the new user to the parent component 
   */
  public async createUserByCloningPermissionsAsync(): Promise<void> {
    // Frontend validation will be shown for these. This is a simple type-guard:
    if (isNone(this.loginName) || isNone(this.email)) {
      return;
    }
    
    const entity: CreateUserByCloningPermissionsInput = ({
      userIdToClone: this.userIdToClone,
      loginName: this.loginName,
      name: this.name,
      email: this.email,
      telephoneNumber: this.phone,
      description: this.description
    });

    const mutation = gql`
      mutation CreateUserByCloningPermissions($entity: CreateUserByCloningPermissionsInput!) {
        createUserByCloningPermissions(entity: $entity) {
          code
          success
          message
        }
      }`;

    const result = await this.runQuery<CreateUserByCloningPermissions, CreateUserByCloningPermissionsVariables>(
                                mutation, 
                                { 
                                    entity
                                });

    if(!result.createUserByCloningPermissions.success) {
      this.createUserByCloningError = result.createUserByCloningPermissions.message;
      return;
    }

    const newUserId = ensureNumber(result.createUserByCloningPermissions.message);
    await this.revalidateAllActiveQueries();
    this.createUserByCloningError = undefined;

    if (this.userWasCloned) {
      this.userWasCloned({ newUserId });
    }
  }
}
