import Button from '$components/buttons/button.react';
import Checkbox from '$components/checkbox/checkbox.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import HaulierDropdown from '$components/dropdowns/haulier-dropdown/haulier-dropdown.react';
import Pill from '$components/pill/pill.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { hasAccessToSecurityLevel } from '$lib/authorizationHelper';
import { isSomething } from '$lib/helpers';
import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  CurrentUserFiltersDocument,
  UserAccess
} from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecurityLevels } from '../../../../../../../../../src/config';
import { getSession } from '../../../../../../../../../src/config/sessionService';
import { FormValues } from '../../../user-security-editor.react';

interface IUserSecurityAccessEditProps {
  userAccess: UserAccess;
}

const UserSecurityAccessEdit: FC<IUserSecurityAccessEditProps> = ({
  userAccess
}) => {
  const { register, control, watch } = useFormContext<FormValues>();
  const { data } = useQuery(CurrentUserFiltersDocument);
  const securityLevelsList = Object.values(SecurityLevels);

  const formCustomerTags = watch('customerTags');
  const formBuidTags = watch('buidTags');
  const existingHaulierTags = watch('haulierTags');

  const [showAllCustomerPills, setShowAllCustomerPills] = useState(false);
  const [showAllBuidPills, setShowAllBuidPills] = useState(false);
  const [showAllHaulierPills, setShowAllHaulierPills] = useState(false);

  const hasAccessToCustomerFilter = (customerFilter: string) =>
    getSession().currentUser.isSuperUser ||
    data?.currentUserFilters.customerFilter.some(filter =>
      customerFilter.toLowerCase().startsWith(filter.toLowerCase())
    );

  const hasAccessToBuidFilter = (buidFilter: string) =>
    getSession().currentUser.isSuperUser ||
    data?.currentUserFilters.buidFilter.some(filter =>
      buidFilter.toLowerCase().startsWith(filter.toLowerCase())
    );

  const hasAccessToHaulierFilter = (haulierFilter: string) =>
    getSession().currentUser.isSuperUser ||
    data?.currentUserFilters.haulierFilter.some(filter =>
      haulierFilter.toLowerCase().startsWith(filter.toLowerCase())
    );

  return (
    <div>
      <SimpleTable>
        {isSomething(userAccess.details.isSuperUser) && (
          <SimpleTableRow>
            <SimpleTableCell>Superuser</SimpleTableCell>
            <SimpleTableCell>
              <Checkbox
                {...register('isSuperUser')}
                checked={watch('isSuperUser')}
              />
            </SimpleTableCell>
          </SimpleTableRow>
        )}
        <SimpleTableRow>
          <SimpleTableCell>SecurityLevel</SimpleTableCell>
          <SimpleTableCell>
            <Controller
              control={control}
              name="securityLevel"
              render={({ field }) => (
                <Dropdown
                  className="block securitylevel"
                  items={securityLevelsList.filter(key =>
                    hasAccessToSecurityLevel(
                      getSession(),
                      securityLevelsList.indexOf(key)
                    )
                  )}
                  searchable={false}
                  itemClicked={v => field.onChange(v?.value)}
                  selected={field.value}
                />
              )}
            />
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>Has access to all access tag</SimpleTableCell>
          <SimpleTableCell>
            <Checkbox
              {...register('hasAccessToAllCustomers')}
              checked={watch('hasAccessToAllCustomers')}
            />
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>Has access to all buids</SimpleTableCell>
          <SimpleTableCell>
            <Checkbox
              {...register('hasAccessToAllBuids')}
              checked={watch('hasAccessToAllBuids')}
            />
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>Site access by access tags</SimpleTableCell>
          <SimpleTableCell></SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>
            <Controller
              control={control}
              name="customerTags"
              render={({ field }) => (
                <CustomerDropdown
                  selected={undefined}
                  itemClicked={v => {
                    if (v.name && formCustomerTags.includes(v.name)) return;
                    return field.onChange([...formCustomerTags, v.name]);
                  }}
                  placeholder="Add customer tags"
                  topElement="IF_QUERY"
                  replaceTopElement={s => {
                    return <div>Add customer-tag: {s}</div>;
                  }}
                />
              )}
            />
          </SimpleTableCell>
          <SimpleTableCell></SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>
            {!formCustomerTags.length && <i>None</i>}
            {!!formCustomerTags.length && (
              <div className="pills">
                <Controller
                  control={control}
                  name="customerTags"
                  render={({ field }) => (
                    <>
                      {(showAllCustomerPills
                        ? formCustomerTags
                        : formCustomerTags.slice(0, 3)
                      ).map((tag, i) => (
                        <Pill
                          key={tag + i} 
                          className="pill"
                          disabled={!hasAccessToCustomerFilter(tag)}
                          iconClicked={() =>
                            field.onChange(
                              formCustomerTags.filter(
                                existingTags => existingTags !== tag
                              )
                            )
                          }
                        >
                          {tag}
                        </Pill>
                      ))}
                    </>
                  )}
                />
                {!showAllCustomerPills && formCustomerTags.length > 3 && (
                  <Button
                    variant="tertiary"
                    className="pill"
                    onClick={() => setShowAllCustomerPills(true)}
                  >
                    +{formCustomerTags.length - 3} more
                  </Button>
                )}
              </div>
            )}
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>Site access by buid</SimpleTableCell>
          <SimpleTableCell></SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>
            <Controller
              control={control}
              name="buidTags"
              render={({ field }) => (
                <BuidDropdown
                  placeholder="Add buid tags"
                  value-path="name"
                  topElement="IF_QUERY"
                  replaceTopElement={s => {
                    return <div>Add buid-tag: {s}</div>;
                  }}
                  itemClicked={v => {
                    if (v.name && formBuidTags.includes(v.name)) return;
                    return field.onChange([...formBuidTags, v.name]);
                  }}
                />
              )}
            />
          </SimpleTableCell>
          <SimpleTableCell></SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>
            {!formBuidTags?.length && <i>None</i>}
            {!!formBuidTags?.length && (
              <div className="pills">
                <Controller
                  control={control}
                  name="buidTags"
                  render={({ field }) => (
                    <>
                      {(showAllBuidPills
                        ? formBuidTags
                        : formBuidTags.slice(0, 3)
                      ).map((tag, i) => (
                        <Pill
                          key={tag + i}
                          className="pill"
                          disabled={!hasAccessToBuidFilter(tag)}
                          iconClicked={() =>
                            field.onChange(
                              formBuidTags.filter(
                                existingTags => existingTags !== tag
                              )
                            )
                          }
                        >
                          {tag}
                        </Pill>
                      ))}
                    </>
                  )}
                />
                {!showAllBuidPills && formBuidTags.length > 3 && (
                  <Button
                    variant="tertiary"
                    className="pill"
                    onClick={() => setShowAllBuidPills(true)}
                  >
                    +{formBuidTags.length - 3} more
                  </Button>
                )}
              </div>
            )}
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>Site access by haulier</SimpleTableCell>
          <SimpleTableCell></SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>
            <Controller
              control={control}
              name="haulierTags"
              render={({ field }) => (
                <HaulierDropdown
                  selected={undefined}
                  placeholder="Add haulier tags"
                  valuePath="haulierTag"
                  displayPath="haulierTag"
                  topElement="IF_QUERY"                  
                  itemClicked={v => {
                    if (v === undefined)
                      return;

                    if (v.text && existingHaulierTags.includes(v.text.toString())) return;
                    return field.onChange([...existingHaulierTags, v.text]);
                  }}
                  replaceTopElement={s => {
                    return <div>Add haulier-tag: {s}</div>;
                  }}
                />
              )}
            />
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>
            {!existingHaulierTags?.length && <i>None</i>}
            {!!existingHaulierTags?.length && (
              <div className="pills">
                <Controller
                  control={control}
                  name="haulierTags"
                  render={({ field }) => (
                    <>
                      {(showAllHaulierPills
                        ? existingHaulierTags
                        : existingHaulierTags.slice(0, 3)
                      ).map((tag, i) => (
                        <Pill
                          key={tag + i}
                          className="pill"
                          disabled={!hasAccessToHaulierFilter(tag)}
                          iconClicked={() =>
                            field.onChange(
                              existingHaulierTags.filter(
                                existingTags => existingTags !== tag
                              )
                            )
                          }
                        >
                          {tag}
                        </Pill>
                      ))}
                    </>
                  )}
                />
                {!showAllHaulierPills && existingHaulierTags.length > 3 && (
                  <Button
                    variant="tertiary"
                    className="pill"
                    onClick={() => setShowAllHaulierPills(true)}
                  >
                    +{existingHaulierTags.length - 3} more
                  </Button>
                )}
              </div>
            )}
          </SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>
    </div>
  );
};

export default UserSecurityAccessEdit;
