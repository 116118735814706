import ActionButton from '$components/actions/action-button/action-button.react';
import ActionContentItem from '$components/actions/action-content-item/action-content-item.react';
import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import Checkbox from '$components/checkbox/checkbox.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { flatMap } from '$lib/arrayHelpers';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  RoleTemplate,
  UserAccess,
  UserAccessFeatureGroup
} from '$typings/graphql-codegen';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ExtendedUserAccessFeatureGroup,
  FormValues
} from '../../../user-security-editor.react';
import './user-security-feature-edit.css';
import classNames from "classnames";

interface IUserSecurityFeatureEditProps {
  roleTemplates?: RoleTemplate[];
  userAccess: UserAccess;
  orderedUserFeatureGroup: UserAccessFeatureGroup[];
}

const getAction = (userAccess: boolean, template: boolean) => {
  if (userAccess === template) return 'unchanged';
  if (userAccess === true && template === false) return 'removed';
  if (userAccess === false && template === true) return 'added';
  return 'unchanged';
};

const UserSecurityFeatureEdit: FC<IUserSecurityFeatureEditProps> = ({
  roleTemplates,
  userAccess,
  orderedUserFeatureGroup
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const { control, setValue, watch } = useFormContext<FormValues>();

  const formUserFeatures = watch('userFeatures');

  const setFeaturesFromTemplate = ({ features, name }: RoleTemplate) => {
    const newPendingUserFeatures: ExtendedUserAccessFeatureGroup[] = orderedUserFeatureGroup.map(
      uf => ({
        ...uf,
        features: uf.features.map(feature => {
          const {
            featureId,
            readEnabled,
            canDelete,
            canRead,
            canWrite,
            deleteEnabled,
            writeEnabled
          } = feature;
          const featureFromTemplate = features.find(
            f => f.featureId === featureId
          );          
          return {
            ...feature,
            canDelete: !deleteEnabled
              ? canDelete
              : featureFromTemplate
              ? featureFromTemplate.delete
              : false,
            canRead: !readEnabled
              ? canRead
              : featureFromTemplate
              ? featureFromTemplate.read
              : false,
            canWrite: !writeEnabled
              ? canWrite
              : featureFromTemplate
              ? featureFromTemplate.write
              : false,
            deleteAction:
              deleteEnabled
                ? getAction(canDelete, featureFromTemplate ? featureFromTemplate.delete : false)
                : undefined,
            readAction:
              readEnabled
                ? getAction(canRead, featureFromTemplate ? featureFromTemplate.read : false)
                : undefined,
            writeAction:
              writeEnabled
                ? getAction(canWrite, featureFromTemplate ? featureFromTemplate.write : false)
                : undefined
          };
        })
      })
    );

    const flattenedNewUserFeatures = flatMap(
      newPendingUserFeatures,
      u => u.features
    );

    const pendingColumns = userAccess.userColumns.map(c => {
      if (!c.connectedToUserFeature) return c;
      const feature = flattenedNewUserFeatures.find(
        f => f.featureId === c.connectedToUserFeature
      );
      if (!feature) return c;
      return {
        ...c,
        selected: feature.canRead || feature.canWrite || feature.canDelete
      };
    });
    
    setValue('appliedRoleTemplateInfoName', name);
    setValue('userFeatures', newPendingUserFeatures);
    setValue('userColumns', pendingColumns);
  };

  const toggleAllUserFeatures = () => {
    const flattenedUserFeatures = flatMap(formUserFeatures, u => u.features);

    const allFeaturesAreChecked = !flattenedUserFeatures.some(
      feature =>
        (!feature.canDelete && feature.deleteEnabled) ||
        (!feature.canRead && feature.readEnabled) ||
        (!feature.canWrite && feature.writeEnabled)
    );

    const newPendingUserFeatures = userAccess.userFeatures.map(uf => ({
      ...uf,
      features: uf.features.map(feature => {
        const {
          readEnabled,
          canDelete,
          canRead,
          canWrite,
          deleteEnabled,
          writeEnabled
        } = feature;
        return {
          ...feature,
          canDelete: deleteEnabled ? !allFeaturesAreChecked : canDelete,
          canRead: readEnabled ? !allFeaturesAreChecked : canRead,
          canWrite: writeEnabled ? !allFeaturesAreChecked : canWrite
        };
      })
    }));

    const pendingColumns = userAccess.userColumns
      .filter(c => c.connectedToUserFeature)
      .map(c => ({ ...c, selected: !allFeaturesAreChecked }));

    setValue('userFeatures', newPendingUserFeatures);
    setValue('userColumns', pendingColumns);
    setValue('appliedRoleTemplateInfoName', undefined);
  };

  return (
    <div className="user-security-features-edit">
      <CardContent>
        <ButtonGroup className="flex">
          <ActionButton
            buttonType="secondary"
            text="Set from template"
            className="toggleButton"
          >
            {roleTemplates?.map((template, i) => (
              <ActionContentItem
                key={template.name + i}
                onClick={() => setFeaturesFromTemplate(template)}
              >
                {template.name}
              </ActionContentItem>
            ))}
          </ActionButton>
          <Button
            variant="secondary"
            onClick={toggleAllUserFeatures}
            className="toggleButton"
          >
            Toggle all features
          </Button>
        </ButtonGroup>
      </CardContent>
      <CardContent className="fill-height user-security-editor-card-content features-tab">
        <Controller
          name="userFeatures"
          control={control}
          render={({ field }) => (
            <>
              {orderedUserFeatureGroup.map((userFeatureGroup, i) => (
                <SimpleTable key={userFeatureGroup.groupKey + i} className="feature-table">
                  <SimpleTableRow className="header">
                    <SimpleTableCell className="text-description">
                      {t(userFeatureGroup.groupKey)}
                    </SimpleTableCell>
                    <SimpleTableCell>Read</SimpleTableCell>
                    <SimpleTableCell>Write</SimpleTableCell>
                    <SimpleTableCell>Delete</SimpleTableCell>
                  </SimpleTableRow>
                  {userFeatureGroup.features.map((userFeature, j) => {
                    const formUserFeature = flatMap(
                      formUserFeatures,
                      u => u.features
                    ).find(f => f.featureId === userFeature.featureId);
                    return (
                      <SimpleTableRow key={`${userFeature.featureId}${j}`}>
                        <SimpleTableCell className="text-description">
                          {t(userFeature.translationKey)}
                        </SimpleTableCell>
                        <SimpleTableCell>
                          <Checkbox
                            disabled={!userFeature.readEnabled}
                            checked={formUserFeature?.canRead}
                            className={classNames(formUserFeature?.readAction)}
                            onChange={() => {
                              const changedCanRead = field.value.map(v => ({
                                ...v,
                                features: v.features.map(f =>
                                  f.featureId !== userFeature.featureId
                                    ? { ...f }
                                    : { ...f, canRead: !f.canRead }
                                )
                              }));
                              field.onChange(changedCanRead);
                              setValue(
                                'appliedRoleTemplateInfoName',
                                undefined
                              );
                            }}
                          ></Checkbox>
                        </SimpleTableCell>
                        <SimpleTableCell>
                          <Checkbox
                            disabled={!userFeature.writeEnabled}
                            checked={formUserFeature?.canWrite}
                            className={classNames(formUserFeature?.writeAction)}
                            onChange={() => {
                              const changedCanWrite = field.value.map(v => ({
                                ...v,
                                features: v.features.map(f =>
                                  f.featureId !== userFeature.featureId
                                    ? { ...f }
                                    : { ...f, canWrite: !f.canWrite }
                                )
                              }));
                              field.onChange(changedCanWrite);
                              setValue(
                                'appliedRoleTemplateInfoName',
                                undefined
                              );
                            }}
                          ></Checkbox>
                        </SimpleTableCell>
                        <SimpleTableCell>
                          <Checkbox
                            disabled={!userFeature.deleteEnabled}
                            checked={formUserFeature?.canDelete}
                            className={classNames(formUserFeature?.deleteAction)}
                            onChange={() => {
                              const changedCanDelete = field.value.map(v => ({
                                ...v,
                                features: v.features.map(f =>
                                  f.featureId !== userFeature.featureId
                                    ? { ...f }
                                    : { ...f, canDelete: !f.canDelete }
                                )
                              }));
                              field.onChange(changedCanDelete);
                              setValue(
                                'appliedRoleTemplateInfoName',
                                undefined
                              );
                            }}
                          ></Checkbox>
                        </SimpleTableCell>
                      </SimpleTableRow>
                    );
                  })}
                </SimpleTable>
              ))}
            </>
          )}
        />
      </CardContent>
    </div>
  );
};

export default UserSecurityFeatureEdit;
