import Button from '$components/buttons/button.react';
import Icon from '$components/icons/icon/icon.react';
import Pill from '$components/pill/pill.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { isSomething } from '$lib/helpers';
import { UserAccess } from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { SecurityLevels } from '../../../../../../../../../src/config';

interface IUserSecurityAccessViewProps {
  userAccess: UserAccess;
}

export const mapToSecurityLevel = (v: number) => {
  switch (v) {
    case 0:
      return SecurityLevels[0];
    case 1:
      return SecurityLevels[1];
    case 2:
      return SecurityLevels[2];
    case 3:
      return SecurityLevels[3];
    default:
      return SecurityLevels[0];
  }
};

const UserSecurityAccessView: FC<IUserSecurityAccessViewProps> = ({
  userAccess
}) => {
  const [showAllCustomerPills, setShowAllCustomerPills] = useState(false);
  const [showAllBuidsPills, setShowAllBuidsPills] = useState(false);
  const [showAllHaulierPills, setShowAllHaulierPills] = useState(false);

  return (
    <SimpleTable>
      {isSomething(userAccess.details.isSuperUser) && (
        <SimpleTableRow>
          <SimpleTableCell>Superuser</SimpleTableCell>
          <SimpleTableCell>
            {userAccess.details.isSuperUser && <Icon name="fa-check" />}
          </SimpleTableCell>
        </SimpleTableRow>
      )}
      <SimpleTableRow>
        <SimpleTableCell>SecurityLevel</SimpleTableCell>
        <SimpleTableCell className='securitylevel'>
          {mapToSecurityLevel(userAccess.details.securityLevel)}
        </SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>Has access to all customers</SimpleTableCell>
        <SimpleTableCell>
          {userAccess.details.hasAccessToAllCustomers && (
            <Icon name="fa-check" />
          )}
        </SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>Has access to all buids</SimpleTableCell>
        <SimpleTableCell>
          {userAccess.details.hasAccessToAllBuids && <Icon name="fa-check" />}
        </SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>Site access by access tags</SimpleTableCell>
        <SimpleTableCell></SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>
          {!userAccess.details.customerTags.length && <i>None</i>}
          {!!userAccess.details.customerTags.length && (
            <div className="pills">
              {(showAllCustomerPills
                ? userAccess.details.customerTags
                : userAccess.details.customerTags.slice(0, 3)
              ).map((ct, i) => (
                <Pill key={ct + i} className="pill" disabled>
                  {ct}
                </Pill>
              ))}
              {!showAllCustomerPills &&
                userAccess.details.customerTags.length > 3 && (
                  <Button
                    variant="tertiary"
                    className="pill"
                    onClick={() => setShowAllCustomerPills(true)}
                  >
                    +{userAccess.details.customerTags.length - 3} more
                  </Button>
                )}
            </div>
          )}
        </SimpleTableCell>
        <SimpleTableCell></SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>Site access by BUID</SimpleTableCell>
        <SimpleTableCell></SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>
          {!userAccess.details.buidTags.length && <i>None</i>}
          {!!userAccess.details.buidTags.length && (
            <div className="pills">
              {(showAllBuidsPills
                ? userAccess.details.buidTags
                : userAccess.details.buidTags.slice(0, 3)
              ).map((ct, i) => (
                <Pill key={ct + i} className="pill" disabled>
                  {ct}
                </Pill>
              ))}
              {!showAllBuidsPills && userAccess.details.buidTags.length > 3 && (
                <Button
                  variant="tertiary"
                  className="pill"
                  onClick={() => setShowAllBuidsPills(true)}
                >
                  + {userAccess.details.buidTags.length - 3} more
                </Button>
              )}
            </div>
          )}
        </SimpleTableCell>
        <SimpleTableCell></SimpleTableCell>
      </SimpleTableRow>

      <SimpleTableRow>
        <SimpleTableCell>Site access by haulier</SimpleTableCell>
        <SimpleTableCell></SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>
          {!userAccess.details.haulierTags.length && <i>None</i>}
          {!!userAccess.details.haulierTags.length && (
            <div className="pills">
              {(showAllHaulierPills
                ? userAccess.details.haulierTags
                : userAccess.details.haulierTags.slice(0, 3)
              ).map((ct, i) => (
                <Pill key={ct + i} className="pill" disabled>
                  {ct}
                </Pill>
              ))}
              {!showAllHaulierPills &&
                userAccess.details.haulierTags.length > 3 && (
                  <Button
                    variant="tertiary"
                    className="pill"
                    onClick={() => setShowAllHaulierPills(true)}
                  >
                    +{userAccess.details.haulierTags.length - 3} more
                  </Button>
                )}
            </div>
          )}
        </SimpleTableCell>
        <SimpleTableCell></SimpleTableCell>
      </SimpleTableRow>
    </SimpleTable>
  );
};

export default UserSecurityAccessView;
