import { changePendingUserAccessDetails, IUserAccessDetails } from '$pages/usermanager/usermanagerReducer';
import { bindable, inlineView } from 'aurelia-framework';
import UserSecurityEditorReact, { IUserSecurityEditorProps } from './user-security-editor.react';
import { ReactWrapper } from '$pages/common/react-wrapper';
import { store } from '../../../../../../src/reducers/store';
import { Dispatch } from 'redux';

@inlineView(`<template><div ref="react"></div></template>`)
export class UserSecurityEditor extends ReactWrapper<IUserSecurityEditorProps> {
  protected dispatch: Dispatch<any>;

  @bindable() userId: number;
  @bindable({ changeHandler: 'rerender' }) isEditing = false;
  @bindable() editClicked: () => {};
  @bindable() editingDone: () => {};

  constructor() {
    super(UserSecurityEditorReact, () => ({
      userId: this.userId,
      editClicked: this.editClicked,
      editDone: this.editingDone,
      isEditing: this.isEditing,
      formChanged: this.formChanged
    }));
    this.dispatch = store.dispatch;

  }

  formChanged = (values: Partial<IUserAccessDetails>) => {
    this.dispatch(
      changePendingUserAccessDetails(values)
    );
  }
  
}
