import { BaseViewModel } from '../../../common';
import { getLogger } from 'aurelia-logging';
import './usermanagercreation.css';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class UserManagerCreation extends BaseViewModel<void> {
  constructor(private router: Router) {
    super(getLogger('usermanagercreation'));
  }

  creationCancelled = () => {
    this.router.navigateBack();
  };
}
