import { bindable } from 'aurelia-framework';
import UserChangeLogReact, { IUserChangeLogProps } from './user-changelog-react';
import { ReactWrapper } from '$pages/common/react-wrapper';

export class UserChangeLog extends ReactWrapper<IUserChangeLogProps> {
  @bindable() userId: number;
  
  constructor() {
    super(UserChangeLogReact, () => ({
      userId: this.userId      
    }));
  }  
}
