import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Checkbox from '$components/checkbox/checkbox.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import RoleTemplateDropdown from '$components/dropdowns/role-template-dropdown/role-template-dropdown';
import FormElement from '$components/forms/form-element/form-element.react';
import EmailField from '$components/inputs/email-input/email-field.react';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import TextField from '$components/textbox/text-field.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { removeNoneFromArray } from '$lib/arrayHelpers';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import {
  AvailableAccssTagsDocument,
  CheckUsermanagerEmailDocument,
  CheckUsermanagerEmailQuery,
  CheckUsernameAvailibleDocument,
  GetCreateOrModifyUserTicketDocument,
  RoleTemplatesDocument,
  SortDirection,
  UserManagerSortEnum
} from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ServiceNowTicketUserCreation } from '../common/servicenow-userticket-details/servicenow-userticket-details';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import { SiteDetailsTabEnum } from '$typings/graphql';
import Icon from '$components/icons/icon/icon.react';
import { ensureNumber } from '$lib/numberHelpers';
import RenderIf from '$components/render-if/render-if';
import ActionButton from '$components/actions/action-button/action-button.react';
import ActionContentItem from '$components/actions/action-content-item/action-content-item.react';

export interface IFormValues {  
  internalUser: boolean;
  loginName: string;
  name: string;
  email: string;
  telephoneNumber: string;
  description: string;
  neverExpires: boolean;
  customerId: number;
  buidId: number;
  serviceNowTicket?: string;
  templateId?: number;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
  inactivityExpirationDays: number;
}

interface IUserinfoFormProps {
  onCancel: () => void;
  onSave: (values: IFormValues) => Promise<void>;
  onServiceNowLookup?: (ticket: ServiceNowTicketUserCreation) => void;
  defaultValues?: Partial<IFormValues>;
  mode?: 'create' | 'edit';
  showTemplateDropdown?: boolean;
}


const UserinfoForm: FC<IUserinfoFormProps> = ({
  onCancel,
  onSave,
  onServiceNowLookup,
  defaultValues,
  mode,
  showTemplateDropdown = true
}) => {
  const [isFetchingTicket, setIsFetchingTicket] = useState(false);
  const [ticketError, setTicketError] = useState<string>(); 
  const [checkOnly, setCheckOnly] = useState(false);   
  const [existingUsersWithSameEmail, setExistingUsersWithSameEmail] = useState<{ name?: string | null; id: string }[]>([]);  
  const { data: roleTemplates } = useQuery(RoleTemplatesDocument);
  const { data: accessTags } = useQuery(AvailableAccssTagsDocument);   
  
  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch, 
    formState: { isSubmitting, errors }
  } = useForm<IFormValues>({ defaultValues });
  const [t] = useCaseInsensitiveTranslation();

  const [neverExpires] = watch(['neverExpires'])
  const [internalUser, setInternalUser] = useState(defaultValues?.internalUser ?? false); 

  async function validateUsername(newValue: string | undefined) {
    let input = newValue?.trim();

    if (!input) {
      return t('UI_UserManager_InvalidUsername');
    }

    const regex = /^[a-z0-9äöëæøåü!.@_\-\s]{3,50}$/gim;
    if (!regex.test(input)) {
      return t('UI_UserManager_InvalidUsername');
    }

    if (newValue !== defaultValues?.loginName) {
      const res = await mutate(CheckUsernameAvailibleDocument, {
        userName: newValue
      });

      if (!res.checkUsernameAvailible) {
        return 'Username is already used by another user';
      }
    }

    return true;
  }


  async function checkIfEmailExists(email: string, checkOnly: boolean = false) {
    if (email?.trim() === defaultValues?.email?.trim()) return 0;
    const checkEmailVariables = {
      freeTextQuery: '',
      first: 10,
      offset: 0,
      sortDirection: SortDirection.Asc,
      sortProperty: UserManagerSortEnum.Username,
      filters: JSON.stringify([
        {
          exclude: false,
          field: 'email',
          type: 'string',
          filters: [{ value: email.trim(), selected: true }],
          partialMatch: true
        }
      ])
    };    

    const setUsers = (res: CheckUsermanagerEmailQuery) => {
      const existingNames = removeNoneFromArray(
        res.elasticSearchPages.userManager.data.edges.map(s => ({
          name: s.name,
          id: s.userId
        }))
      );      
      
      setCheckOnly(checkOnly);
      setExistingUsersWithSameEmail(existingNames);      
    };

    const res = await mutate(
      CheckUsermanagerEmailDocument,
      checkEmailVariables,
      false,
      setUsers
    );

    return res.elasticSearchPages.userManager.data.totalCount;
  }

  const onSubmit: SubmitHandler<IFormValues> = async values => {
    //Check of email address has already been performed
    if (checkOnly)
      await onSave(values);
    else 
    {
      const numberOfExistingUsers = await checkIfEmailExists(values.email);

      if (!numberOfExistingUsers) {
        await onSave(values);
      }
    }
  };

  /*
  If "bussiness_segment": "Nitrates" AND if "yara_a_number_if_the_user_is_a_yara_employee" is true, pre-set User Profile to "internal Nitrates User"
  If "bussiness_segment": "Nitrates" then preset profile "External Nitrates"
  If "bussiness_segment": "Reagents (Air1)" or "Sales and Marketing" or "Noxcare" or "Basechem" or "Easyfeed" AND if "yara_a_number_if_the_user_is_a_yara_employee" is true, pre-set User profile to "internal & EBS"
  If "bussiness_segment": "Reagents (Air1)" or "Sales and Marketing" or "Noxcare" or "Basechem" or "Easyfeed" AND if "yara_a_number_if_the_user_is_a_yara_employee" is false, pre-set User role template to "External user"
*/
  const getUserTemplate = (isInternalUser: boolean, businessSegment: string) => {
    switch (businessSegment) {
      case 'Nitrates':
        if (isInternalUser)
          return 'Internal Nitrates user';
        else
          return 'External Nitrates user';      
      
      case 'Reagents (Air1)':
      case 'Sales and Marketing':
      case 'Noxcare':
      case 'Basechem':
      case 'Easyfeed':
        if (isInternalUser)
          return 'Internal & EBS';      
        else
          return 'External user';      
      
      default:
        return 'External user';      
    }            
  }

  const setCustomerAndBuidByAccessTag = (accessTagName: string) => {
    //Try finding accessTagId
    const accessTag = accessTags?.customers.find(c => c.name?.toLowerCase().trim() == accessTagName.toLowerCase().trim());

    if (accessTag) {
      setValue('customerId', ensureNumber(accessTag.customerId));
      if (accessTag.buidId)
        setValue('buidId', ensureNumber(accessTag.buidId));
    }
  }

  const setCustomerAndBuidByAccessTagId = (accessTagId: number) => {
    //Try finding accessTagId
    const accessTag = accessTags?.customers.find(c => ensureNumber(c.customerId) == accessTagId);

    if (accessTag) {
      setValue('customerId', ensureNumber(accessTag.customerId));
      if (accessTag.buidId)
        setValue('buidId', ensureNumber(accessTag.buidId));
    }
  }


  const lookupServiceNow = async (ticketNumber?: string, skipEmailCheck: boolean = false) => {
    if (!ticketNumber)
      return;

    try {
      let isInternalUser = false;
      control._reset();
      setValue('serviceNowTicket', ticketNumber);
      setIsFetchingTicket(true);      
      
      const ticket = await mutate(GetCreateOrModifyUserTicketDocument, {
        ticketNumber: ticketNumber
      });

      if (ticket.getCreateOrModifyUserTicket?.action !== 'Create') {        
        setIsFetchingTicket(false);
        setTicketError("UI_UserManager_SerivceNow_Error_WrongTicketType");
        return;
      }

      if (onServiceNowLookup)
        onServiceNowLookup(ticket.getCreateOrModifyUserTicket)        

      if (ticket.getCreateOrModifyUserTicket?.email && !skipEmailCheck) {
        const numberOfExistingUsers = await checkIfEmailExists(ticket.getCreateOrModifyUserTicket?.email, true);
        if (numberOfExistingUsers) {
          setIsFetchingTicket(false);
          return;
        }
      }
      
      if (ticket.getCreateOrModifyUserTicket?.email)
        setValue('email', ticket.getCreateOrModifyUserTicket?.email);
      else
        setValue('email', '');

      if (ticket.getCreateOrModifyUserTicket?.fullName)
        setValue('name', ticket.getCreateOrModifyUserTicket?.fullName);
      else
        setValue('name', '');

      if (ticket.getCreateOrModifyUserTicket?.yaraNumber) {
        setValue('loginName', ticket.getCreateOrModifyUserTicket?.yaraNumber.toLocaleLowerCase());
        isInternalUser = true;
        setValue('internalUser', isInternalUser);
        setInternalUser(isInternalUser);        
      }
      else
      {
        isInternalUser = false;
        setValue('internalUser', isInternalUser);
        setInternalUser(isInternalUser);        
        setValue('loginName', ticket.getCreateOrModifyUserTicket?.email ?? '');
      }
      
      if (ticket.getCreateOrModifyUserTicket?.phoneNumber)
        setValue('telephoneNumber', ticket.getCreateOrModifyUserTicket?.phoneNumber);
      else
        setValue('telephoneNumber', '');

      if (ticket.getCreateOrModifyUserTicket?.description)
        setValue('description', ticket.getCreateOrModifyUserTicket?.description);
      else
        setValue('description', '');

      //Set role template based on business segment and isInternalUser
      const businessSegment = ticket.getCreateOrModifyUserTicket?.businessSegment;

      if (businessSegment) {
        const userTemplate = getUserTemplate(isInternalUser, businessSegment);        
        const roleTemplate = roleTemplates?.roleTemplates.find(t => t.name == userTemplate);
                
        setValue('templateId', roleTemplate?.roleTemplateId ?? 0);

        if (roleTemplate && roleTemplate.inactivityExpirationDays) {
          setValue('inactivityExpirationDays', ensureNumber(roleTemplate.inactivityExpirationDays));
        }
      }
      else
      {
        setValue('templateId', 0);
      }

      
      //Try parsing accessTag and use to set access tag, standard accesstag and responsible buid 
      if (ticket.getCreateOrModifyUserTicket?.accessTag) {
        setCustomerAndBuidByAccessTag(ticket.getCreateOrModifyUserTicket?.accessTag);
      }
      
    } catch (e) {
      if (e.toString().indexOf('404') >= 0) {
        setTicketError('UI_UserManager_SerivceNow_Error_TicketNotFound');
      }      
      else {
        setTicketError('UI_UserManager_SerivceNow_Error_Generic');
      }
    }
    setIsFetchingTicket(false);
  }

  return (
    <>    
      <form onSubmit={handleSubmit(onSubmit)}>        
        <CardContent>
          {mode == 'create' && (
          <FormElement label={t('UI_UserManager_ServiceNowTicket')}>            
            <Controller
              control={control}
              name="serviceNowTicket"              
              render={({ field }) => (
                <div style={{display: "flex", gap: "4px"}}>
                  <TextField
                    {...register('serviceNowTicket')}
                    placeholder={t('UI_UserManager_ServiceNowTicket_Placeholder')}                
                  />
                  <Button icon={isFetchingTicket ? 'fa-spinner fa-pulse' : 'fa-search'} disabled={!field?.value || isFetchingTicket} onClick={() => lookupServiceNow(field?.value)}></Button>            
                </div>)}
            />                                
          </FormElement>
          )}

        {mode !== 'create' && (
          <FormElement label={t('UI_UserManager_RoleTemplate')}>            
            <ActionButton
              buttonType="secondary"
              text="Set from template"
              className="toggleButton"
            >
              {roleTemplates?.roleTemplates?.map((template, i) => (
                <ActionContentItem
                  key={template.name + i}
                  onClick={() => {                    
                      setInternalUser(template.internalOnly);
                      setValue('internalUser', template.internalOnly);                      
                      setValue('inactivityExpirationDays', ensureNumber(template.inactivityExpirationDays));
                      setValue('neverExpires', false);                      
                    }
                  }
                >
                  {template.name}
                </ActionContentItem>
              ))}
            </ActionButton>
          </FormElement>
          )}

          <FormElement label={t('UI_UserManager_InternalUser')}>
            <Checkbox
              {...register('internalUser')}                            
              checked={internalUser}
            />
          </FormElement>
          <FormElement label={t('UI_UserManager_UserName')}>
            <TextField
              {...register('loginName', {
                required: t('UI_Common_Fields_RequiredField') as string,
                validate: validateUsername
              })}
              placeholder={t('UI_UserManager_UserName')}
              error={errors.loginName?.message}
            />
          </FormElement>
          <FormElement label={t('UI_Name')}>
            <TextField
              {...register('name', {
                required: t('UI_Common_Fields_RequiredField') as string
              })}
              placeholder={t('UI_Name')}
              error={errors.name?.message}
            />
          </FormElement>
          <FormElement label={t('UI_Email')}>
            <EmailField
              required
              register={register}
              name="email"
              placeholder={t('UI_Email')}
              error={errors.email?.message}
            />
          </FormElement>
          <FormElement label={t('UI_UserManager_PhoneNumber')}>
            <TextField
              {...register('telephoneNumber')}
              placeholder={t('UI_UserManager_PhoneNumber')}
            />
          </FormElement>
          <FormElement label={t('UI_Description')}>
            <TextField
              {...register('description')}
              placeholder={t('UI_Description')}
            />
          </FormElement>
          <FormElement label={t('ui_userprofile_labels_usersettings_siteDetailsDefaultTab')}>
            <Controller
              control={control}
              name="siteDetailsDefaultTab"
              render={({ field }) => (
                <Dropdown<SiteDetailsTabEnum>
                  replaceItemElement={item => (
                    <div className="date-range-item-wrapper">
                      <Icon name="fa-tab" />
                      <span>{item}</span>
                    </div>
                  )}
                  selected={field.value ?? SiteDetailsTabEnum.Details}
                  items={ [SiteDetailsTabEnum.Details, SiteDetailsTabEnum.Graph, SiteDetailsTabEnum.Tanks] }
                  itemClicked={item => {
                    field.onChange(item?.value as SiteDetailsTabEnum);
                  }}
                />
              )}
            />
          </FormElement>          
          <RenderIf if={!neverExpires}>
            <FormElement label={t('UI_UserManager_ExpiresDays')}>
              <TextField type='number' required min={14}
                {...register('inactivityExpirationDays', {valueAsNumber: true})}              
                placeholder={t('UI_UserManager_ExpiresDays')}              
              />
            </FormElement>           
          </RenderIf>
          <FormElement label={t('UI_UserManager_NeverExpires')}>
            <Checkbox
              {...register('neverExpires')}
              checked={neverExpires}              
            />
          </FormElement>
          <FormElement label={t('UI_UserManager_DefaultCustomer')}>
            <Controller
              control={control}
              name="customerId"
              rules={{
                required: t('UI_Common_Fields_RequiredField') as string
              }}
              render={({ field }) => (
                <CustomerDropdown
                  error={!!errors.customerId?.message}
                  placeholder={t('UI_UserManager_DefaultCustomer')}
                  itemClicked={({ customerId }) => {
                      customerId && field.onChange(+customerId);
                      customerId && setCustomerAndBuidByAccessTagId(+customerId);
                    }
                  }
                  selected={field.value?.toString()}
                />
              )}
            />
            <ErrorText>{errors.customerId?.message}</ErrorText>
          </FormElement>
          <FormElement label={t('UI_UserManager_DefaultBuid')}>
            <Controller
              control={control}
              name="buidId"
              rules={{
                required: t('UI_Common_Fields_RequiredField') as string
              }}
              render={({ field }) => (
                <BuidDropdown
                  error={!!errors.buidId?.message}
                  placeholder={t('UI_UserManager_DefaultBuid')}
                  itemClicked={({ buidId }) =>
                    buidId && field.onChange(+buidId)
                  }
                  selected={field.value?.toString()}
                />
              )}
            />
            <ErrorText>{errors.buidId?.message}</ErrorText>
          </FormElement>
          {showTemplateDropdown && (
            <FormElement label={t('Apply role template')}>
              <Controller
                control={control}
                name="templateId"
                render={({ field }) => (
                  <RoleTemplateDropdown
                    placeholder={t('Apply role template')}
                    itemClicked={({ roleTemplateId }) => {
                      if (roleTemplateId) {
                        field.onChange(+roleTemplateId);
                        const template = roleTemplates?.roleTemplates.find(t => t.roleTemplateId == ensureNumber(roleTemplateId));
                        if (template) {
                          setInternalUser(template.internalOnly);
                          setValue('internalUser', template.internalOnly);                      
                          setValue('inactivityExpirationDays', ensureNumber(template.inactivityExpirationDays));
                          setValue('neverExpires', false);                            
                        }
                      }
                    }}
                    internalUser={watch('internalUser')}
                    selected={field.value?.toString()}
                  />
                )}
              />
            </FormElement>
          )}
        </CardContent>
        <CardFooter className="form-group-actions">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" processing={isSubmitting} variant="primary">
            Save changes
          </Button>
        </CardFooter>
      </form>

      {!!existingUsersWithSameEmail.length && (
        <RequireConfirmation
          confirmType="primary"
          submitText="Yes"
          onCancel={() => setExistingUsersWithSameEmail([])}
          onConfirm={() => {
            if (checkOnly) {
              setExistingUsersWithSameEmail([]);
              lookupServiceNow(watch().serviceNowTicket, true);
            }
            else
              onSave(watch())
            }
          }>
          <p>
            One or more accounts may already exist with this email address, are
            you sure you want to use this email address?
          </p>
          <p>Existing accounts with this email:</p>
          {existingUsersWithSameEmail.map(u => (
            <a key={u.id} href={`/usermanager/details/${u.id}`} rel="noreferrer" target={'_blank'}>{u.name}</a>
          ))}
        </RequireConfirmation>
      )}


     {ticketError && (
        <ModalCard overflow={false} placementOnMobile={'bottom'}>
          <CardHeader>
            <p>{t('UI_UserManager_SerivceNow_Error_Header')}</p>
          </CardHeader>

          <CardContent className="flex" style={{flex: "1 1 0px"}}>
              {t(ticketError)}
          </CardContent>

          <CardFooter>
            <div className="action-buttons margin-top">                            
                <Button className="margin-left" onClick={() => setTicketError(undefined)}>OK</Button>              
            </div>
          </CardFooter>
        </ModalCard>
    )}
    </>
  );
};

export default UserinfoForm;
