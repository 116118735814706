import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { GetCreateOrModifyUserTicketDocument, GetCreateOrModifyUserTicketMutation } from "$typings/graphql-codegen";
import React, { FC, useEffect, useState } from 'react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import CardContent from '$components/cards/card-content/card-content.react';
import Icon from '$components/icons/icon/icon.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import Button from '$components/buttons/button.react';
import { mutate } from "$lib/hooks/fetch-utillities";

export type ServiceNowTicketUserCreation = GetCreateOrModifyUserTicketMutation["getCreateOrModifyUserTicket"];

export interface IServiceNowUserTicketDetailsProps {
  ticket?: string;
  ticketData?: ServiceNowTicketUserCreation;  
}

const ServiceNowUserTicketDetails: FC<IServiceNowUserTicketDetailsProps> = ({
  ticket,
  ticketData: ticketDataFromProps
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [ticketData, setTicketData] = useState<ServiceNowTicketUserCreation>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  
  const lookupServiceNow = async (ticketNumber: string) => {
    try {      
      setError(false);
      setIsLoading(true);
      const ticket = await mutate(GetCreateOrModifyUserTicketDocument, {
        ticketNumber: ticketNumber
      });

      if (ticket && ticket?.getCreateOrModifyUserTicket)
      {
        setTicketData(ticket?.getCreateOrModifyUserTicket);
      }
    }
    catch (e) {
      setError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (ticketDataFromProps)
      setTicketData(ticketDataFromProps);
    else if (ticket)
      lookupServiceNow(ticket);
  }, [ticket, ticketDataFromProps]);

  return (
    <Card className='fill-width'>      
      <CardHeader className="hideOnMobile">Ticket details</CardHeader>
      <CardContent>
        {error && (
          <>
          <ErrorText>{t("UI_UserManager_SerivceNow_Error_UnableToFetch")}</ErrorText>
          <br/>
          <Button variant="secondary" icon="fa-refresh" iconPlacement="left" className="mar_lm" onClick={() => ticket && lookupServiceNow(ticket)}>{t("UI_Common_Retry")}</Button>
          </>
        )}
        {!error && (
        <SimpleTable layout='fixed'>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_UserManager_ServiceNowTicket')}</SimpleTableCell>
            <SimpleTableCell>{isLoading ? (
              <Icon name={'fa-spinner fa-pulse'} className="text" />
              ) : (
              <><a href={ticketData?.serviceNowUrl || ''} target="_blank" rel="noreferrer">{ticketData?.requestItem}</a> <Icon name="fa-external-link" /></>
            )} 
            </SimpleTableCell>
          </SimpleTableRow>            
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_UserManager_OnBehalfOf')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.onBehalfOf}</SimpleTableCell>
          </SimpleTableRow>            
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_UserManager_UserName')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.yaraNumber}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_Name')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.fullName}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_Email')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.email}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_UserManager_PhoneNumber')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.phoneNumber}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_UserManager_BusinessSegment')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.businessSegment}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_Description')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.description}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('UI_UserManager_AccessTag')}</SimpleTableCell>
            <SimpleTableCell>{ticketData?.accessTag}</SimpleTableCell>
          </SimpleTableRow>
        </SimpleTable>       
        )} 
      </CardContent>
    </Card>
  );
};

export default ServiceNowUserTicketDetails;
