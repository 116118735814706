import { useQuery } from '$lib/hooks/fetch-utillities';
import React, { FC, useEffect, useState } from 'react';
import { CardContent } from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import './user-changelog.css';
import { CardWithHeader } from '$components/cards/mui-card';
import LoadingBarsReact from '$components/loading-bars/loading-bars.react';
import { HistoryList } from '$components/history-list/history-list/history-list.react';
import { IHistoryItem } from '$interfaces/application';
import { UserChangelogDocument } from '$typings/graphql-codegen';

export interface IUserChangeLogProps {
  userId: number;  
}

const UserChangeLogReact: FC<IUserChangeLogProps> = ({
  userId  
}) => {
  const [t] = useCaseInsensitiveTranslation();
  
  const { data, loading } = useQuery(UserChangelogDocument, { userId });
  const [ userHistoryItems, setUserHistoryItems ] = useState<IHistoryItem[]>([]);

  useEffect(() => {
    if (data && data.userChangelog) {
      setUserHistoryItems(data.userChangelog.map((item) => { 
        return { 
          timeStamp: item.modified.toString(), 
          metaInformation: item.modifiedBy, 
          text: item.description 
        }
      }));
    }
  }, [data, loading]);

  return (
    <div className="user-changelog-component">
      <CardWithHeader className='user-changelog-card' headerText={t('usermanager_userchangelog_title')}>
        {loading && (
          <CardContent className="component-card-content">                
              <LoadingBarsReact/>
          </CardContent>
        )}            

        {(!loading && userHistoryItems.length == 0 ) && (
          <CardContent className="component-card-content overflow-y-auto">                
              <p>{t('usermanager_userchangelog_no_history')}</p>
          </CardContent>
        )}            

        {(!loading && userHistoryItems.length > 0) && (
          <CardContent className="component-card-content overflow-y-auto">                
              <HistoryList items={userHistoryItems} convertTimezone={true}></HistoryList>
          </CardContent>
        )}            
      </CardWithHeader>      
    </div>
  );
};

export default UserChangeLogReact;
