import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import {
  AddUserInfoDocument,
  AddUserInfoMutation,
} from '$typings/graphql-codegen';
import React, { CSSProperties, FC, useState } from 'react';
import UserinfoForm, { IFormValues } from '../userinfo-form/userinfo-form';
import ServiceNowUserTicketDetails, { ServiceNowTicketUserCreation } from '../common/servicenow-userticket-details/servicenow-userticket-details';
import { useIsMobile } from '$lib/hooks/isMobile';
import classNames from "classnames";

interface ICreateUserCardProps {
  cancel: () => {};
}

const CreateUserCard: FC<ICreateUserCardProps> = ({ cancel }) => {
  function onSuccess(res: AddUserInfoMutation) {
    const newUserId = res.addUserInfo.userId;
      document.location.href = `/usermanager/details/${newUserId}`;
  }

  const isMobile = useIsMobile();
  const [ticketData, setTicketData] = useState<ServiceNowTicketUserCreation>();

  async function addUserInfoMutation(values: IFormValues) {
    const variables = {
      userInfo: {
        userId: 0,
        securityLevel: 0,
        avatarUrl: '',
        ...values
      }
    };
    await mutate(AddUserInfoDocument, variables, false, onSuccess);
  }

  function getCardStyle(isMobile: boolean) {
    let cardStyle: CSSProperties = {
      gap: '1rem',
      display: 'flex',
      margin: '1rem'
    };

    if (isMobile)
      cardStyle = {
        ...cardStyle,
        flexDirection: 'column'
      }

    return cardStyle;
  }

  return (
    <div style={getCardStyle(isMobile)}>
      <Card className={classNames(isMobile && 'fill-width')}>
        <CardHeader className="hideOnMobile">Create user</CardHeader>
        <UserinfoForm mode='create' onCancel={cancel} onSave={addUserInfoMutation} onServiceNowLookup={(ticketData) => setTicketData(ticketData)} />
      </Card>

      {ticketData && (
        <div style={{ maxWidth: 400 }}>
          <ServiceNowUserTicketDetails ticketData={ticketData} />
        </div>
      )}
    </div>
  );
};

export default CreateUserCard;
