import CardContent from '$components/cards/card-content/card-content.react';
import Icon from '$components/icons/icon/icon.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { UserAccessFeatureGroup } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import classNames from 'classnames';

interface IUserSecurityFeatureViewProps {
  orderedUserFeatureGroup: UserAccessFeatureGroup[];
  isMobile: boolean;
}

//Returns groups with at least one selected column
function userFeatureGroupWithAccess(groupedUserFeatures : UserAccessFeatureGroup[]) {
  return groupedUserFeatures.filter(g => g.features.filter(c => c.canRead || c.canWrite || c.canDelete).length > 0);
}

const UserSecurityFeatureView: FC<IUserSecurityFeatureViewProps> = ({
  orderedUserFeatureGroup,
  isMobile
}) => {
  const [t] = useCaseInsensitiveTranslation();
  return (
    <CardContent
      className={classNames(
        isMobile && 'fill-height',
        'user-security-editor-card-content',
        'features-tab'
      )}>
      {userFeatureGroupWithAccess(orderedUserFeatureGroup).map((userFeatureGroup, i) => (
        <SimpleTable key={"userSecurityFeatureTable" + i} className="feature-table">
          <SimpleTableRow className="header">
            <SimpleTableCell className="text-description">
              {t(userFeatureGroup.groupKey)}
            </SimpleTableCell>
            <SimpleTableCell>Read</SimpleTableCell>
            <SimpleTableCell>Write</SimpleTableCell>
            <SimpleTableCell>Delete</SimpleTableCell>
          </SimpleTableRow>
          {userFeatureGroup.features.filter(f => f.canRead || f.canWrite || f.canDelete).map((userFeature, j) => (
            <SimpleTableRow key={"userSecuritFeatureTableRow" + j}>
              <SimpleTableCell className="text-description">
                {t(userFeature.translationKey)}
              </SimpleTableCell>
              <SimpleTableCell>
                {userFeature.canRead && <Icon name="fa-check" />}
              </SimpleTableCell>
              <SimpleTableCell>
                {userFeature.canWrite && <Icon name="fa-check" />}
              </SimpleTableCell>
              <SimpleTableCell>
                {userFeature.canDelete && <Icon name="fa-check" />}
              </SimpleTableCell>
            </SimpleTableRow>
          ))}
        </SimpleTable>
      ))}
    </CardContent>
  );
};

export default UserSecurityFeatureView;
